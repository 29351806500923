import React, { useEffect, useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import HomeBanner from "./HomeBanner";
import Partners from "./Partners";
import Testimonials from "./Testimonials";
import PopUpVideo from "../../components/popUpVideo";
import GetStarted from "./GetStarted";
import AboutUs from "./AboutUs";
import Features from "./Features";
import Usage from "./Usage";
import rightImage from "../../assets/images/Home/home-right-bg.webp"
import Mobile from "./Mobile";
import Barcode from "./Barcode";

const Home = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);

  const ipApi = "https://api.ipgeolocation.io/ipgeo?apiKey=edf546dbfa5a43878a6d6b9a661eecc0&fields=country_code2"; 

  useEffect(() => {
    async function fetchLocation() {
      try {
        const response = await fetch(ipApi);
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("currentLocation", data.country_code2);
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchLocation();
  }, []);


  return (
    <>
      <HomeBanner
        caption="A state-of-the-art communication solution"
        first_title="Connect"
        second_title="Collaborate."
        details="Facilitate online collaboration with high-quality video conferencing, engage your audience, and streamline your workflow."
        isShowButton={true}
        image={rightImage}
      />
      <AboutUs />
      {/* <Mobile /> */}
      <Features />
      <Partners />
      {/* <Testimonials /> */}
      <Usage />
      {/* <Barcode /> */}
      <GetStarted />
    </>
  );
};

export default Home;
