import React from "react";
import video from "../../assets/images/Features/video.svg";
import chat from "../../assets/images/Features/chat.svg";
import whiteboard from "../../assets/images/Features/whiteboard.svg";
import calendarInvite from "../../assets/images/Features/calendarInvite.svg";
import search from "../../assets/images/Features/search.svg";
import meetings from "../../assets/images/Features/meetings.svg";
import record from "../../assets/images/Features/record.svg";
import polling from "../../assets/images/Features/polling.svg";
import profile from "../../assets/images/Features/profile.svg";
import room from "../../assets/images/Features/room.svg";
import background from "../../assets/images/Features/background.svg";
import invite from "../../assets/images/Features/invite.svg";
import notes from "../../assets/images/Features/notes.svg";
import screenshare from "../../assets/images/Features/screenshare.svg";
import dashboard from "../../assets/images/Features/dashboard.svg";
import connection from "../../assets/images/Features/connection.svg";
import history from "../../assets/images/Features/history.svg";
import "../../assets/styles/componentStyles/usage-card.scss";

const Usage = () => {
    const features = [
        {
            icon: video,
            title: 'Share External Video'
        },
        {
            icon: chat,
            title: 'Chat'
        },
        {
            icon: whiteboard,
            title: 'Whiteboard'
        },
        {
            icon: calendarInvite,
            title: 'Calendar Invite'
        },
        {
            icon: search,
            title: 'Word Search on playback'
        },
        {
            icon: meetings,
            title: 'Schedule meetings'
        },
        {
            icon: record,
            title: 'Record Session'
        },
        {
            icon: polling,
            title: 'Polling'
        },
        {
            icon: profile,
            title: 'Account Profile'
        },
        {
            icon: room,
            title: 'Waiting Room'
        },
        {
            icon: whiteboard,
            title: 'Multi-User Whiteboard'
        },
        {
            icon: background,
            title: 'Virtual Background'
        },
        {
            icon: invite,
            title: 'Unique Invite Url'
        },
        {
            icon: notes,
            title: 'Share Notes'
        },
        {
            icon: screenshare,
            title: 'Screen Share'
        },
        {
            icon: dashboard,
            title: 'Analytics Dashboard'
        },
        {
            icon: connection,
            title: 'Connection Status'
        },
        {
            icon: history,
            title: 'Meeting History'
        },
    ]
    return (
        <div className="sm-container">
            <div className="usage">
                <div className="container">
                    <div className="testimonial-content">
                        <div className="no-gutters">
                            <div className="d-flex align-items-center justify-content-start">
                                <div className="testimonial-text">
                                    <h3>
                                        Say goodbye to limitations, <br /> and hello to possibilities
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="no-gutters">
                            <div className="testimonial-text">
                                Studio integrates a variety of features that can help you stay connected <br />
                                with your team or audience, regardless of your location or device
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters testimonial-content testimonial-list">
                        {features.map((feature, index) => (
                            <div key={index} className="testimonial mb-3 d-flex align-items-center flex-column justify-content-center">
                                <img src={feature.icon} alt='bg-image' />
                                <div className="caption mb-3">
                                    {feature.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
        </div >
    );
};

export default Usage;
