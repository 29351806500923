import React from 'react';
import image from '../../assets/images/user.png';
import quote from '../../assets/images/quote.svg'; 
import tap from "../../assets/images/tap.png";
import gardenAcademy from "../../assets/images/gardenAcademy.jpg";
import babcock from "../../assets/images/Partners/babcock.png";
import '../../assets/styles/pageStyles/testimonial.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const Testimonials = () => {
    const testimonials = [
        {
            image: tap,
            body: "Studio has made the transition to remote working smooth and efficient for my team. It's almost as if we are in the same space even though we are miles apart.",
            user: 'Michael Oluwole',
            position: 'Co-founder and CEO',
            company: 'Tap',
        },
        {
            image: gardenAcademy,
            body: 'It was easy to navigate. The network indicator is my favourite feature. I like the fact that I can know that my network is probably not strong enough to continue the meeting.',
            user: 'Bukola Odeyemi',
            position: 'Student',
            company: 'Garden Academy',
        },
        {
            image: babcock,
            body: "One word: Simplicity. It's incredible that we can have such a platform in Nigeria.",
            user: 'Damilare Akinwunmi',
            position: 'Instructor',
            company: 'Babcock',
        },
        {
            image: tap,
            body: "You can gain access to it on the web without having to download an actual application It is actually very functional and easy to navigate.",
            user: 'Ariyo Samuel',
            position: 'Product Manager',
            company: 'Tap',
        }
    ];

    return (
        <Carousel autoPlay infiniteLoop showStatus={false}>
            {testimonials.map((data) => (
                <div className='comment'>
                    <div className='container'>
                        <div
                            className='row align-items-center justify-content-center m-auto card'>
                            <div className='content'>
                                <div className='testimonial_container'>
                                    <div className='quote'>
                                        <img src={quote} alt='quote' className='img' />
                                    </div>
                                    <div className='row align-items-center text-align-center'>
                                        <div className='col-2'>
                                            <img src={data.image} width='82px' alt='icon' style={{ borderRadius: '50%' }} />
                                        </div>
                                        <div className='col-10' style={{ textAlign: 'start' }}>
                                            <div className='content-text'>{data.body}</div>
                                            <div className='content-author'>
                                                <span className='content-user'>{data.user}</span>&nbsp;&nbsp;<span>|</span>&nbsp;&nbsp;
                                                <span className='content-info'>
                                                    {data.position} - {data.company}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default Testimonials;
