import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MobileNav from "./MobileNav";

import "../assets/styles/componentStyles/navigation.scss";

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [isFooter, setIsFooter] = useState(true);
  const location = useLocation();
  const path = location?.pathname;

  useEffect(() => {
    setOpen(false);
  }, [path]);

  useEffect(() => {
    path == "/auth" ? setIsFooter(false) : setIsFooter(true);
  }, [path]);

  const specialPaths = [
    "/login",
    "/signup",
    "/join",
    "/auth",
    "/pricing",
    "/privacy-policy",
    "/terms-of-use",
  ];

  // const isFooter = !specialPaths.includes(path);
  const isLight = specialPaths.includes(path);

  const toggleMobileNav = () => setOpen(!open);
  return (
    <div>
      <Header
        toggleMobileNav={toggleMobileNav}
        open={open}
        isLight={isLight}
      />
      <MobileNav open={open} toggleMobileNav={toggleMobileNav} />

      {children}
      {isFooter && <Footer />}
    </div>
  );
};

export default Layout;
