import React from 'react';
import Button from "../../components/Button";
import mockup2 from "../../assets/images/Home/mockup2.png";
import "../../assets/styles/componentStyles/mobile.scss";
import { Bounce } from 'react-reveal';
import QRCode from 'qrcode.react';

const Barcode = () => {
    return (
        <div className="barcode-BG">
            <div className="container">
                <div className="d-none d-lg-flex row align-items-center justify-content-center mockup-section">
                    <div className="col-12 col-lg-4">
                        <img src={mockup2} alt='mockup2' />
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='mobile-text'>
                            Take Studio <br />everywhere you go
                        </div>
                        <div className='mobile-caption'>
                            Scan the bar code to get started with <br />Studio mobile app on your iOS device
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='barcode-box'>
                            <QRCode
                                value="https://onelink.to/k6s3w6"
                                size={256}
                                bgColor="#ffffff"
                                fgColor="#000000"
                                level="Q"
                                includeMargin={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex d-lg-none row align-items-center justify-content-center mockup-section">
                    <div className="col-12 col-lg-4">
                        <img src={mockup2} alt='mockup2' />
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className='mobile-text'>
                            Take Studio <br />everywhere you go
                        </div>
                        <div className='mobile-caption'>
                            Scan the bar code to get started with <br />Studio mobile app on your iOS device
                        </div>
                    </div>
                    <div className="d-block d-md-none col-12 col-lg-4">
                        <div className='barcode-box'>
                            <QRCode
                                value="https://onelink.to/k6s3w6"
                                size={128}
                                bgColor="#ffffff"
                                fgColor="#000000"
                                level="Q"
                                includeMargin={true}
                            />
                        </div>
                    </div>
                    <div className="d-none d-md-block col-12 col-lg-4">
                        <div className='barcode-box'>
                            <QRCode
                                value="https://onelink.to/k6s3w6"
                                size={256}
                                bgColor="#ffffff"
                                fgColor="#000000"
                                level="Q"
                                includeMargin={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Barcode;
