import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/componentStyles/features.scss";

const FeaturesCard = ({ data }) => {
    const { icon, color, bgColor, tag, title, content } = data;
    return (
        <>
            <Link to={`/solutions/${tag.toLowerCase()}`}>
                <div
                    className={`features-card-container d-flex flex-column align-items-start`}
                    style={{ color: color, background: bgColor }}
                >
                    <img src={icon} alt="icon" />
                    <div className="tag"> {tag} </div>
                    <h2> {title} </h2>
                    <p> {content} </p>
                </div>
            </Link>
        </>
    );
};

export default FeaturesCard;
