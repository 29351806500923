import React, { useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import HomeBanner from "../Home/HomeBanner";
import Partners from "../Home/Partners";
import Testimonials from "../Home/Testimonials";
import GetStarted from "../Home/GetStarted";
import rightImage from "../../assets/images/Solutions/healthcare.png"
import FeaturesAlt from "./FeaturesAlt";
import Experience from "./Experience";
import experience1 from "../../assets/images/Solutions/remote-consultation.png";
import experience2 from "../../assets/images/Solutions/improved-access.png";
import experience3 from "../../assets/images/Solutions/collaboration.png";
import experience12 from "../../assets/images/Solutions/remote-consultation-xs.png";
import experience22 from "../../assets/images/Solutions/improved-access-xs.png";
import experience32 from "../../assets/images/Solutions/collaboration-xs.png";

const Healthcare = () => {

  const experiences = [
    {
      bgColor: '#2B55B7',
      image1: experience1,
      image2: experience12,
      title: 'Remote Consultations',
      caption: 'Give patients access to medical consultations remotely, reducing the need for in-person appointments and enabling more flexible scheduling.'
    },
    {
      bgColor: '#EAEEF8',
      image1: experience2,
      image2: experience22,
      title: 'Improved Access',
      caption: 'Studio helps improve access to healthcare for patients in remote or underserved areas, where healthcare services may be limited.'
    },
    {
      bgColor: '#0CA17D',
      image1: experience3,
      image2: experience32,
      title: 'Collaboration',
      caption: "Empower healthcare professionals to collaborate on patient care, improving communication and coordination among healthcare teams."
    },
  ];
  return (
    <>
      <HomeBanner
        caption="Solutions for healthcare"
        first_title="Deliver"
        second_title="Exceptional Care."
        details="Studio is here to aid you in enhancing patient engagement, offering outstanding telehealth experiences that can reach patients regardless of location, and fostering improved collaboration among healthcare teams."
        isShowButton={false}
        image={rightImage}
      />
      <Experience
        experiences={experiences}
        first_title="The Telehealth"
        second_title="Experience"
      />
      <Partners />
      {/* <Testimonials /> */}
      <FeaturesAlt />
      <GetStarted />
    </>
  );
};

export default Healthcare;
