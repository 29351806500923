import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Typewriter from "typewriter-effect";
import Button from "../components/Button";
import "../assets/styles/pageStyles/authpage.scss";


const AuthPage = () => {
    const [open, setOpen] = useState(false);
    const toggleMobileNav = () => setOpen(!open);

    const gotoSignupPage = () => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/signup';
    }

    return (
        <div className="main">
            <div className="row align-items-center full-page">
                <div className="auth-intro-first col-12 col-lg-6" data-aos="fade-zoom-in">
                    <div className="d-flex align-items-center container text-section">
                        <div className="auth-hero-text">
                            <h1>
                                Individual
                            </h1>

                            <p>
                                Studio is an intuitive space for effective teamwork, learning and collaboration. Communicate better with Studio.
                            </p>

                            <div style={{ marginBottom: '40px' }}>
                                <Button label="Individual Sign Up" onClick={gotoSignupPage} style={{ marginRight: '20px', margin: '10px 20px 10px 0', width: '200px' }} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="auth-intro-second col-12 col-lg-6" data-aos="fade-zoom-in">
                    <div className="d-flex align-items-center container text-section">
                        <div className="auth-hero-text">
                            <h1>
                                Business
                            </h1>

                            <p>
                                Studio is an intuitive space for effective teamwork, learning and collaboration. Communicate better with Studio.
                            </p>

                            <div style={{ marginBottom: '40px' }}>
                                <Button label="Business Sign Up" onClick={gotoSignupPage} style={{ marginRight: '20px', margin: '10px 20px 10px 0', width: '200px' }} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
