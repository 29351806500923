import React from "react";
// import NewsLetter from "./NewsLetter";
import { Link } from "react-router-dom";

import "../assets/styles/componentStyles/footer.scss";
import studioLogo from "../assets/images/logo-default.png";
import scrollButton from "../assets/images/scroll.svg";
import instagram from "../assets/images/instagram.svg";
import youtube from "../assets/images/youtube.svg";
import linkedin from "../assets/images/linkedin.svg";
import twitter from "../assets/images/twitter.svg";
import facebook from "../assets/images/facebook.svg";

const Footer = () => {

    const socials = [
        {
            icon: facebook,
            link: 'https://www.facebook.com/thestudioapp'
        },
        {
            icon: linkedin,
            link: 'https://www.linkedin.com/company/thestudioapp'
        },
        {
            icon: twitter,
            link: 'https://twitter.com/thestudio_app'
        },
        // {
        //     icon: youtube,
        //     link: ''
        // },
        {
            icon: instagram,
            link: 'https://www.instagram.com/thestudio_app/'
        },
    ]

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container">
                    <div className="footer--two">
                        <p className="footer-item size">
                            Copyright © {new Date().getFullYear()} Studio. All rights reserved.
                            <Link to="/privacy-policy">
                                <span className="footer-link size" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy Policy</span>
                            </Link>
                            <Link to="/cookie-policy">
                                <span className="footer-link size" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookie Policy</span>
                            </Link>
                            <Link to="/terms-of-use">
                                <span className="footer-link size" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms of Use</span>
                            </Link>
                        </p>
                        <p className="footer-item-mobile size">
                            <div className="d-flex align-items-center"> Copyright © {new Date().getFullYear()} Studio.
                                <span> All rights reserved.</span></div>
                            <div className="d-flex align-items-center">
                                <Link to="/privacy-policy">
                                    <span className="footer-link size">Privacy Policy</span>
                                </Link>
                                <Link to="/cookie-policy">
                                    <span className="footer-link size">Cookie Policy</span>
                                </Link>
                                <Link to="/terms-of-use">
                                    <span className="footer-link size">Terms of Use</span>
                                </Link>
                            </div>
                        </p>
                        <div className="social-media">
                            {socials.map((social) => (
                                <a
                                    href={social.link}
                                    target="_blank"
                                >   <img src={social.icon} className="icon" alt="social media" style={{ marginRight: '5px' }} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment >
    );
};

export default Footer;
