import React from 'react';
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import footerimg from "../../assets/images/Home/footerImage.webp";
import Goto from "../../assets/images/Home/arrow-right.svg";
import Gotoxs from "../../assets/images/Home/arrow-right-xs.svg";
import Star from "../../assets/images/Home/star.svg";
import Starxs from "../../assets/images/Home/star-xs.svg";
import '../../assets/styles/pageStyles/footer.scss';

const GetStarted = () => {
    const history = useHistory();

    const gotoSignupPage = () => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/plan_selection';
    }

    return (
        <div className='get-started'>
            <div className="container" data-aos="fade-zoom-in">
                <div className='d-none d-lg-block'>
                    <div className='footer-container row d-flex'>
                        <div className='footer-image m-0 p-0'>
                            <img src={footerimg} alt='footer-image' />
                        </div>
                        <div className="footer-text d-flex justify-content-start">
                            <div className="get-started-text">
                                <h3>
                                    Ready for <br />
                                    an Experience?
                                </h3>
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <Button label="Try Studio - Free"
                                        onClick={gotoSignupPage}
                                        icon={<img src={Goto} alt="tick-icon" style={{ marginLeft: 7 }} />}
                                        variant="outlined-colored-black" />
                                    <div className='pricing-nav' onClick={() => history.push("/pricing")}>
                                        See Pricing <img src={Star} alt='star-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='footer-container row d-flex d-lg-none'>
                        <div className='footer-image m-0 p-0'>
                            <img src={footerimg} alt='footer-image' />
                        </div>
                        <div className="footer-text d-flex justify-content-start">
                            <div className="get-started-text">
                                <h3>
                                    Ready for an Experience?
                                </h3>
                                <div className="d-flex align-items-center justify-content-start mt-3">
                                    <Button
                                        label="Try Studio - Free"
                                        onClick={gotoSignupPage}
                                        className="button-xs"
                                        icon={<img src={Gotoxs} alt="tick-icon" style={{ marginLeft: 7 }} />}
                                        variant="outlined-colored-black"
                                    />
                                    <div className='pricing-nav' onClick={() => history.push("/pricing")}>
                                        See Pricing <img src={Starxs} alt='star-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStarted;
