import React, { useState } from 'react'
import { Link } from "react-router-dom";
import "../assets/styles/pageStyles/home.scss";

const Privacy = () => {

    return (
        <div className="main-container">
            <div class="container mb-8 mt-4">
                <div class="text-center lh-base mb-5">
                    <h1> Privacy Policy Statement</h1>
                </div>

                <section class="lh-base mb-5">
                    <h2>Preface</h2>
                    <article>
                        <p>Edutech Advanced Business Technologies Ltd, its affiliates (including VigiLearn Technologies FZE), and its subsidiaries (Edutech Global/Edutech/We/Our/Us) strongly believe in providing total privacy and protection to our customer&#39;s personal data. The information our customers give us is gotten with full consent, processed on a legal basis, and is not sold, rented, loaned, or otherwise disclosed to third parties.</p>

                        <p>Our policies are created to provide protection of the confidentiality and security of our customer&#39;s personal information. We only require the minimum amount of personal information needed to fulfill our service to our customers. Our privacy standards are in compliance with the National Data Protection Regulation (NDPR) and the General Data Protection Regulation (GDPR).</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Policy Overview</h2>
                    <article>
                        <p>This Privacy Policy describes the process involved in the acquisition, processing, porting, storage, and disposing of your personal data in connection with our websites, portals, mobile applications, tools, and services. This Privacy Policy also governs your rights regarding the foregoing. Edutech takes pride in securing and maintaining the privacy of the data we collect from you.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Scope and Consent</h2>
                    <article>
                        <p>This policy is enforced after you consent to it when you sign-up, and access our products, services, content, features, technologies, or functions offered from our websites, related sites, portals, applications, and services. However, Edutech is not responsible for the handling of information gotten by third-party customer/sites through the use of our tools, portal, and services and will not be held liable for any breach or misuse of collected information.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Rights</h2>
                    <article>
                        <p>You have the right of access to your personal data being processed by Edutech which includes requesting an update, rectification, erasure, asking for a copy of your personal data, a withdrawal of consent at any time, complaining to a data protection authority, without affecting the lawfulness of processing based on consent given before the withdrawal.</p>
                        <p>You can request a restriction or deletion of your personal data based on the following: </p>
                        <ul>
                            <li><p>Non-legitimate grounds for the processing</p></li>
                            <li><p>Unlawful processing</p></li>
                            <li><p>Erasure is required for compliance with a legal obligation</p></li>
                            <li><p>Inaccuracy of personal data</p></li>
                            <li><p>Change in initial purpose</p></li>
                        </ul>
                        <p>
                            All requests shall be made in writing to the Data Protection
                            Officer via email at&nbsp;
                            <a
                                href="mailto:dpo@edutech.global"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                dpo@edutech.global
                            </a>
                        </p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Use of Personal Information</h2>
                    <article>
                        <p>The collection and use of personal data by Edutech are guided by certain principles. These principles state that personal data should: </p>
                        <ul>
                            <li><p>be processed fairly, lawfully, and in a transparent manner.</p></li>
                            <li><p>be obtained for a specified and lawful purpose and shall not be processed in any manner incompatible with such purposes.</p></li>
                            <li><p>be adequate, relevant, and limited to what is necessary to fulfill the purpose of processing.</p></li>
                            <li><p>be accurate and, where necessary, up to date.</p></li>
                            <li><p>Not be kept for longer than necessary for the purpose of processing.</p></li>
                            <li><p>be processed in accordance with the data subject&#39;s rights.</p></li>
                            <li><p>be kept safe from unauthorized processing, and accidental loss, damage, or destruction using adequate technical and organizational measures.</p></li>
                        </ul>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Personal Data Collected and Used in Edutech Global</h2>
                    <article>
                        <p>
                            We collect the following personal information in order to provide personalized and improved experience. Personal Data may be given to us directly by you or by people or companies authorized by you to act on your behalf. We may also collect Personal Data about you from third parties in connection with our services. No Personal Data about you will be collected without your consent.
                        </p>

                        <p class="mb-5">
                            Where we need to collect Personal Data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to provide the services envisaged or requested.
                        </p>

                        <div class="mb-5">
                            <h4>Automatic Information</h4>
                            <p>In general, website visitors do not need to provide personalized information to us. We may collect "aggregate data," i.e., group data with no personal identifiers such as IP address, device type, geo-location information, computer and connection information, mobile network information, statistics on page views, traffic to and from the sites, referral URL, ad data, and standard web log data and other information.</p>

                            <p>We use this aggregate data to help us understand how the Sites are being used and to improve usability. We also use it to enhance the quality and availability of products and services we offer.</p>
                        </div>

                        <div class="mb-5">
                            <h4>Provided Information</h4>
                            <p>Personal data that are provided and retained allows Edutech to provide services to its users. Edutech solely holds the information and engages in no contact-sharing program with other organizations. The information collected is;</p>

                            <p><strong>Contact Information:</strong> first and last name, email address. phone number and any other information required to process your information as a user of our platforms.</p>

                            <p>
                                <strong>Website Cookies:</strong> (small text files) when a user visits a website and these cookies are used to analyze aggregate user behavior on our website. Edutech websites ask permission of the visitor prior to setting cookies. Should the visitor agree, our server shall only collect the following information:
                            </p>
                            <ul>
                                <li><p>The visitor&#39;s IP address (including the domain name associated with the IP address, i.e., using reverse lookup).</p></li>
                                <li><p>The date and time of the visit to the website. </p></li>
                                <li><p>The pages visited on the website. </p></li>
                                <li><p>The browser being used.</p></li>
                                <li>
                                    <p>In addition, where this is available, Edutech shall also collect:
                                    </p>

                                    <ul>
                                        <li><p>The country from which the visitor is accessing the website </p></li>
                                        <li><p>The language of the browser being used.</p></li>
                                        <li><p>The website from which the visitor is accessing the Edutech website. </p></li>
                                        <li><p>The search word used (if the site is accessed via a search engine). </p></li>
                                        <li><p>The type of connection and operating system.</p></li>
                                    </ul>
                                </li>
                            </ul>

                            <p class="mt-4">
                                We only use these data to improve the visitor&#39;s website experience.
                                Please review our&nbsp;
                                <Link to="/cookie-policy">
                                    Cookie Policy
                                </Link>
                                &nbsp;to learn more about how we use cookies.
                            </p>
                        </div>

                        <p><strong>Third-Party Source:</strong> We may collect information from third-party websites such as social media sites which authorize us to access, store and use users&#39; information which will be handled in accordance with this policy.</p>

                        <p><strong>Mobile Devices:</strong> Some of our applications can be accessed through mobile application or mobile-optimized websites from which we collect users&#39; data. This policy applies to access and use of data through mobile devices.</p>

                        <p><strong>Surveys:</strong> In order to ensure that the services we offer meet your requirements, we may ask for your feedback in form of surveys and polls. Any feedback received from you shall only be used for the purpose of improving our services and shall not be disclosed and used for research and publication purposes.</p>

                        <p><strong>Inquiries:</strong> When an inquiry is sent to us through our contact form, we use the personal data that you have stated in the contact form to send a response to you. Any personal data received from you shall not be used for any other purpose without your prior consent and knowledge and shall not be disclosed.</p>

                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Duration Of Storage</h2>
                    <article>
                        <p>We shall store your personal data until they are no longer necessary for the purpose for which we collected it. Your information is only retained in accordance with legal and regulatory requirements for the collected data.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Data Storage and Security</h2>
                    <article>
                        <p>
                            Edutech uses a security-cleared data processor to store files and data on secure cloud-based servers hosted in the Republic of Ireland region of Amazon Web Services, and the North Europe Region of Microsoft Azure. The data processors are certified under the applicable frameworks and thereby guarantee and operate an appropriate standard of data protection and data security. Regardless of where your information is processed, we apply the same protections described in this policy and you have the right (upon your request) to be informed of the appropriate safeguards for data protection in the foreign country.
                        </p>

                        <p>
                            We protect your Personal Data using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure, and alteration. All data is accessed via secure connections and stored on encrypted servers and encrypted storage services. We also use firewalls and physical access controls for our data centres, and information access authorization controls.
                        </p>

                        <p>
                            Despite our efforts to establish a secure environment for the website, you should be aware that no information is completely secure on the internet. Therefore, you should always take the necessary safeguards and precautions on your own equipment. In the event of an actual or suspected breach of your Personal Data, we will use our best effort to remedy the breach within <strong>one (1) month</strong> from the date we report the breach to you.
                        </p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Children's Privacy</h2>
                    <article>
                        <p>We do not use our platforms to knowingly solicit data from children or individuals under the age of Thirteen (13). Where a parent or guardian discovers that a child or individual under the age of Thirteen (13) has provided us with his/her Personal Data without their consent, he or she should contact us at&nbsp;
                            <a
                                href="mailto:dpo@edutech.global"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                dpo@edutech.global
                            </a>
                            and we shall immediately delete the user&#39;s account from our servers.</p>
                    </article>
                </section>


                <section class="lh-base mb-5">
                    <h2>Accuracy Of Information </h2>
                    <article>
                        <ul>
                            <li><p>The Company shall take reasonable steps to ensure personal data is accurate. </p></li>
                            <li><p>Where necessary for the lawful basis on which data is processed, steps shall be put in place to ensure that personal data is kept up to date.</p></li>
                            <li><p>The Company shall ensure that updated personal data reflect across all boards to which it is being used.</p></li>
                        </ul>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Archiving/removal</h2>
                    <article>
                        <ul>
                            <li><p>To ensure that personal data is kept for no longer than necessary, the Company puts in place a data retention policy for each area in which personal data is processed and reviews this process annually. </p></li>
                            <li><p>This retention policy considers what data should/must be retained, for how long, and why.</p></li>
                        </ul>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Transfer of Personal Data</h2>
                    <article>
                        <p>
                            We do not rent or sell your personally identifiable information to other individuals or organizations.
                            However, we may transfer your personal data to third parties when it is necessary to provide you with our service. Third parties could include:
                        </p>
                        <ul>
                            <li><p>Undertakings within Edutech</p></li>
                            <li><p>Business partners</p></li>
                            <li><p>Professional advisors</p></li>
                            <li><p>Legal or regulatory authority</p></li>
                            <li><p>Application program interface (API) Users</p></li>
                            <li><p>Security-cleared data processors/subcontractors, who are assisting us or the group with IT or other services.</p></li>
                        </ul>
                        <p>
                            When we transfer your personal data to business partners, you should be aware that they might have stored personal data concerning you collected through other means, e.g., if you have been in contact with them in another context.
                        </p>

                        <p>
                            We also transfer your personal data to the above or other third parties if we are obliged to do so according to legislation or in order to protect our or the group&#39;s interests in legal disputes.
                        </p>
                    </article>
                </section>


                <section class="lh-base mb-5">
                    <h2>Transfer of Personal Data to Third Countries </h2>
                    <article>
                        <p>
                            Edutech partners with various technology vendors from time to time. This shall result in a transfer of personal data to a third country or international organization.
                        </p>

                        <p>
                            In order to ensure an equal level of security for such transfer in accordance with the NDPR, Edutech has chosen to work only with vendors that have entered Standard Contractual Clauses with us.
                        </p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Changes to This Policy</h2>
                    <article>
                        <p>
                            We may update this Policy from time to time without any prior notice to you or consent. Thus, you are advised to consult this Platform periodically for any changes. We will notify you of any changes by posting the revised Policy on this Platform. Please note that changes shall be effective immediately after they are updated on this Platform.
                        </p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Contact</h2>
                    <article>
                        <p>
                            If you want to lodge a complaint over our processing of your personal data or have further requests, please contact the Data Protection Officer directly at&nbsp;
                            <a
                                href="mailto:dpo@edutech.global"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                dpo@edutech.global
                            </a>.
                        </p>

                        <p>
                            We maintain an incident response plan used in dealing with incidents relating to unlawful disclosure, loss, alteration, destruction, and access to our customer&#39;s personal data collected, transmitted, stored or, processed in any way.
                        </p>

                        <p>
                            We will work with the National Information Technology Development Agency (NITDA) to resolve any issues that we cannot resolve with you directly.
                        </p>
                    </article>
                </section>
                <h5>Date of last revision: 27th June, 2024</h5>
            </div>

        </div>
    )
};

export default Privacy