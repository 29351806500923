import React, { useState } from "react";
import GetStarted from "../Home/GetStarted";
import Blog from "./Blog";

const Resources = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  return (
    <>
      <Blog />
      <GetStarted />
    </>
  );
};

export default Resources;
