import React, { useEffect, useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import GetStarted from "../Home/GetStarted";
import FAQ from "./FAQ";
import Plan from "./Plan";

const Home = () => {
    const ipApi = "https://api.ipgeolocation.io/ipgeo?apiKey=edf546dbfa5a43878a6d6b9a661eecc0&fields=country_code2";

    useEffect(() => {
        async function fetchLocation() {
            try {
                const response = await fetch(ipApi);
                if (response.ok) {
                    const data = await response.json();
                    localStorage.setItem("currentLocation", data.country_code2);
                } else {
                    console.error("Error fetching data. Status:", response.status);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }

        fetchLocation();
    }, []);

    return (
        <>
            <Plan />
            <FAQ />
            <GetStarted />
        </>
    );
};

export default Home;
