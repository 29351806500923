import React, { useState } from 'react'
import "../assets/styles/pageStyles/home.scss";

const Privacy = () => {

    return (
        <div className="main-container">
            <div class="container mb-8 mt-4">
                <div class="text-center lh-base mb-5">
                    <h1> Studio Terms Of Use</h1>
                </div>

                <section class="lh-base mb-5">
                    <h2>About Studio</h2>
                    <article>
                        <p>Studio is a video conferencing platform for effective teamwork, learning and collaboration,
                            and a product of <b>VIGILEARN TECHNOLOGIES FZE</b> (referred to as “VIGILEARN, "Company," "us," "our," or "we").
                            It includes the website &nbsp;<a href="https://www.studioapp.net/">https://www.studioapp.net/</a> and the associated domains thereof, applications and other technological solutions (“the Platform”).</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2 class="mb-4">Terms</h2>
                    <article>
                        <ol type="A">
                            <li class="mb-3"><b>(Terms of Use)</b></li>
                            <p>Please read these Terms of Use and our Privacy Policy carefully before using the Platform. These Terms of Use govern your access and use of the Platform. By accessing or using Studio, you agree to be bound by these Terms of Use and our Privacy Policy. We provide the Platform and reserve the right to make changes to the Platform and to these Terms of Use at any time without prior notice. You should review these Terms of Use each time you access this Platform.</p>

                            <ol>
                                <li>
                                    <h5>About Us</h5>
                                    <p>We are a software as a service (SaaS) and technology solutions company that provide a range of technological products and services covering Electronic payment systems, E-learning solutions, data warehousing, reporting systems and management information systems for educational institutions worldwide including, Studio.</p>
                                </li>

                                <li>
                                    <h5>The Agreement</h5>
                                    <p>These Terms of Use is an agreement between you and us. It details our obligations to you. It also governs your use of the Platform and the services available on the Platform. It highlights certain risks on using our services and you must consider such risks carefully as you will be bound by the provision of this Terms of Use through your use of this website, mobile applications, or any of our services.</p>
                                </li>

                                <li>
                                    <h5>Registration</h5>
                                    <p>You represent, warrant, and agree that no materials of any kind submitted through your account or otherwise posted or shared by you through the Platform will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory, fraudulent, or otherwise unlawful material. You further agree not to collect email addresses or other contact information of Users from the Platform or the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications. Additionally, you agree not to use automated scripts to collect information from the Platform for any other purpose. You further agree that you may not use the Platform in any unlawful manner or in any other manner that could damage, disable, overburden, or impair the Platform. In addition, you agree not to use the Platform or the Site to:</p>
                                </li>

                                <li>
                                    <h5>User Representation</h5>
                                    <p>You represent, warrant and agree that no materials of any kind submitted through your account or otherwise posted or shared by you through the Platform will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libellous, defamatory, fraudulent or otherwise unlawful material. You further agree not to collect email addresses or other contact information of Users from the Platform or the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications. Additionally, you agree not to use automated scripts to collect information from the Platform for any other purpose. You further agree that you may not use the Platform in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Platform. In addition, you agree not to use the Platform or the Site to:</p>

                                    <ol>
                                        <li class="mb-2">
                                            upload, post, transmit, share, store or otherwise make available any content that we deem to be harmful, threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent, invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise objectionable;
                                        </li>

                                        <li class="mb-2">
                                            impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with or authority to act on behalf of any person or entity;
                                        </li>

                                        <li class="mb-2">
                                            upload, post, transmit, share or otherwise make available any unsolicited or unauthorized advertising, solicitations, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;
                                        </li>

                                        <li class="mb-2">
                                            upload, post, transmit, share, store or otherwise make publicly available on the Platform any private information of any third party, including, without limitation, addresses, phone numbers, email addresses and credit card numbers except as authorized by the third party for the fulfillment of a genuine purpose during the course of your use of a service(s) provided vide the Platform;
                                        </li>

                                        <li class="mb-2">
                                            upload, post, transmit, share or otherwise make available any material that contains software viruses or any other malicious or tracking computer code, files or programs designed to track, interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment or any information that infringes the intellectual property rights of others;
                                        </li>

                                        <li class="mb-2">intimidate or harass another User.</li>

                                        <li class="mb-2">upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any state, national or international law;</li>

                                        <li class="mb-2">use or attempt to use another's account, service or system without authorization from that person and the Company or create a false identity on the Platform.</li>
                                    </ol>
                                </li>

                                <li class="mt-5">
                                    <h5>Age Restriction</h5>
                                    <p>In order to register for our services on the Platform, you represent and warrant that: You are at least 13 years of age, and you are legally able to enter into a binding contract whether on your own or through a guardian.</p>
                                </li>

                                <li>
                                    <h5>Identity Authentication</h5>
                                    <p>You authorize us, directly or through third parties, to make any inquiries we consider necessary to validate your identity. This may include asking you for further information, requiring you to provide a biometric verification number (BVN), requiring you to take steps to confirm ownership of your email address or financial instruments, ordering a credit report, or verifying your Information against third party databases or through other sources.</p>
                                </li>

                                <li>
                                    <h5>Assignment</h5>
                                    <p>You may not transfer or assign any rights or obligations you have under this Terms of Use without our prior written consent in its sole and absolute discretion. We reserve the right to transfer or assign this Terms of Use or any right or obligation under this Terms of Use at any time.</p>
                                </li>

                                <li>
                                    <h5>General Disclaimer</h5>
                                    <p>The services provided under this Platform are provided to you "as is," without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. We do not warrant the accuracy or completeness of the service, the suitability of the content or the reliability of any advice, opinion, statement, or other information displayed or distributed through the Platform.</p>

                                    <p>You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. We do not warrant that the functions contained in the materials will be uninterrupted or error-free, that defects will be corrected, or that the Portal is free of viruses or other harmful components.</p>
                                </li>

                                <li>
                                    <h5>Communications and Notices to you</h5>
                                    <p>By accepting these Terms of Use, you expressly consent to be contacted by us, our agents, representatives, affiliates, or anyone calling on our behalf for any and all purposes, at any telephone number, or physical or electronic address you provide or at which you may be reached. You agree we may contact you in any way, including SMS messages (including text messages), calls using pre-recorded messages or artificial voice, and calls and messages delivered using auto telephone dialing system or an automatic texting system. Automated messages may be played when the telephone is answered whether by you or someone else. In the event that an agent or representative calls, he or she may also leave a message on your answering machine, voice mail, or send one via text. You also agree that we may contact people in your network should we try to reach you to no avail.</p>
                                </li>

                                <li>
                                    <h5>Consent to Electronic Transactions and Disclosures</h5>
                                    <p>Because the Platform operates via the Internet, whether you choose to participate on our Platform as a User, Administrator or in any other manner, it is necessary for you to consent to transact business with us online and electronically. As part of doing business with us, we also need you to consent to our giving you certain disclosures electronically, either via our Platform or to the email address you provide to us. By agreeing to the Terms of Use, you agree to receive electronically all documents, communications, notices, contracts, and agreements arising from or relating to your use of the Platform and Service.</p>
                                </li>

                                <li>
                                    <h5>Taxes</h5>
                                    <p>We are obliged by the applicable laws to collect these taxes on behalf of the government and remit as at when due, failure of which attracts severe penalties. We are not to be held responsible for advising, estimating, calculating and/or remitting any other appropriate taxes that apply to you in your current jurisdiction and in line with the laws of UAE.</p>

                                    <p>You agree to indemnify us to the full extent of applicable law if any tax authority holds us responsible for any taxes directly or indirectly arising from your use of the Platform.</p>
                                </li>

                                <li>
                                    <h5>Suspension or Termination of Access</h5>
                                    <p>We have the right to deny your access to, and suspend or terminate your access to, the Platform for any reason, including for any violation by you of these Terms of Use.</p>
                                </li>

                                <li>
                                    <h5>Intellectual Property</h5>
                                    <ol>
                                        <li class="mb-2">All Intellectual Property Rights existing or belonging to a Party prior to this Agreement and intended to be used for the purpose of the Platform, shall remain the sole property of that Party, and each Party agrees not to question nor dispute the ownership of such rights at any time during or after the termination of this Agreement</li>

                                        <li class="mb-2">You agree and acknowledge that VIGILEARN owns all Intellectual Property Rights in the Platform. You agree not to question or dispute the ownership of VIGILEARN’s Intellectual Property Rights in the Platform at any time.</li>

                                        <li class="mb-2">Any license granted under this Agreement shall be non-transferable, non-sub licensable, nonexclusive and shall be granted only for the purpose of fulfilling the respective Party’s rights and obligations under this Agreement.</li>
                                    </ol>
                                </li>

                                <li>
                                    <h5>Copyright and Trademarks</h5>
                                    <p>The Company name, the term “Studio”, the Studio and VigiLearn logo, and all related names, logos, product and service names, designs and slogans are copyright and trademarks of the Company. You must not use such marks without the prior written permission of the Company or as otherwise set out in these Terms of Use. All other names, logos, product and service names, designs and slogans on this platform are the trademarks of their respective owners.</p>
                                </li>

                                <li>
                                    <h5>Limitation of Damages</h5>
                                    <p>To the extent permitted by applicable law, our liability to you for any cause whatsoever, regardless of the form of the action will not exceed USD5. As permitted by applicable law, we exclude all conditions, warranties, representations, or other terms which may apply to our Platform or any content on it, whether express or implied. We will not be liable to any user for any loss or damage, even if foreseeable, arising under or in connection with the use of, or inability to use our Platform.</p>
                                </li>

                                <li>
                                    <h5>Disclaimer</h5>
                                    <p>We do not guarantee the accuracy of any User Content or Third-Party Content. Although we provide rules for User conduct and postings, we do not control and are not responsible for what Users post on the Platform and are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content you may encounter on the Platform or in connection with any User Content or Third-Party Content. We are not responsible for the conduct, whether online or offline, of any User of the service via the Platform.</p>

                                    <p>We cannot guarantee and do not promise any specific results from use of our services via the Platform.</p>

                                    <p>The Platform and the service may be temporarily unavailable from time to time for maintenance or other reasons. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, User communications. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or on the Platform or combination thereof, including injury or damage to Users or to any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the service provided via the Platform.</p>
                                </li>

                                <li>
                                    <h5>Dispute Resolution</h5>
                                    <p>If a dispute or difference arises as to the validity, interpretation, effects or rights and obligations of the Parties under this Agreement, the Parties shall use their best endeavor to reach an amicable settlement of dispute.</p>

                                    <p>If any such dispute is not settled between the Parties within thirty (30) Business Days, the Parties agree that such dispute will be resolved by a Single Arbitrator appointed jointly by the parties in accordance with the provisions of the London Court of International Arbitration (LCIA). The seat of the arbitration shall be Dubai, UAE.</p>

                                    <p>Where the Parties fail to agree on a single Arbitrator, within a period of ten (10) days from the date a notice of Arbitration filed by either Party, the President of the Chartered Institute of Arbitrators UK shall nominate an Arbitrator on the application of either Party, the decision of the Arbitrator shall be  binding on the Parties, and shall include an order as to the costs of such arbitration, which each Party shall pay such costs.</p>
                                </li>

                                <li>
                                    <h5>Applicable Law</h5>
                                    <p>These Terms of Use shall be interpreted and governed by the laws of England and Wales.</p>
                                </li>

                                <li>
                                    <h5>Waiver and Severability</h5>
                                    <p>Our failure to enforce a provision of these Terms is not a waiver of our right to do so later. If any provision of these Terms is found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term or terms will be substituted reflecting our intent as closely as possible.</p>
                                </li>

                                <li>
                                    <h5>Contact Us</h5>
                                    <p>Any question regarding the use of the Platform, or comments about the services provided via the Platform or its contents should be directed to
                                        &nbsp; <a
                                            href="mailto:support@studioapp.net"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            support@studioapp.net
                                        </a>
                                    </p>
                                </li>

                                <li>
                                    <h5>Studio Integration</h5>
                                    <p>Where you want Studio integrated into your learning system, you can contact us and we will reach out to you within 72 hours.</p>
                                </li>

                                <li>
                                    <h5>Modifications</h5>
                                    <p>We may modify and update the Terms of Use at any time. Your continued use of the Platform after any modifications to these Terms of Use shall mean you accept those modifications. Any aspect of the Platform or the services provided by the Platform may be amended, changed, modified, supplemented, deleted, or updated without notice at our sole discretion.</p>
                                </li>
                            </ol>
                        </ol> </article>
                </section>

                <h5>Date of last revision: 20th February 2023</h5>
            </div>
        </div>
    )
};

export default Privacy