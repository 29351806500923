import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useLocation } from "react-router-dom";
import connect from "../../assets/images/Home/connect.png"
import goldticket from "../../assets/images/Home/Gold-ticket.webp"
import underline from "../../assets/images/Home/underline.svg"
import "../../assets/styles/pageStyles/banner.scss";


const HomeBanner = ({ caption, first_title, second_title, details, isShowButton, image }) => {
    const [open, setOpen] = useState(false);
    const toggleMobileNav = () => setOpen(!open);

    const history = useHistory();
    const location = useLocation();
    const path = location.pathname;

    const gotoSignupPage = () => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/plan_selection';
    }

    return (
        <div className={`home-intro ${path === "/" ? 'home-bg-intro' : ''}`} data-aos="fade-zoom-in">
            <div className="container">
                <div className="banner-container">
                    <Fade left>
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="home-hero-text">
                                    <h4>
                                        {caption}
                                    </h4>
                                    <h1>
                                        <span style={{ color: '#0E347E' }}> {first_title} </span>
                                        {isShowButton && <img src={connect} alt="connect" />}<br />
                                        <span style={{ color: '#2B55B7' }}> {second_title}</span>
                                    </h1>
                                    <div className='underline'>
                                        <img src={underline} alt='underline' />
                                    </div>
                                    <p>
                                        {details}
                                    </p>

                                    <div>
                                        <Button label="Try Studio - Free" onClick={gotoSignupPage} className="home-btn-1" />

                                        {isShowButton && <Link to="/pricing">
                                            <Button label="See Pricing" variant="outlined-colored" className="home-btn-2" />
                                        </Link>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 md-block">
                                <div className="home-hero-image">
                                    <img fetchpriority="high" src={image} alt="image" width="100%" />
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
            {path === "/" && <div className="ticket-tag">
                <img src={goldticket} className="xs-width" width="auto" alt="ticket"/>
            </div>}
        </div>
    );
};

export default HomeBanner;
