import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Button from "../../components/Button";
import "../../assets/styles/pageStyles/plan.scss";
import Mark from "../../assets/images/blue-tick.svg";
import underline from "../../assets/images/underline.svg";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PricingCard from '../../components/PricingCard';
import { useState } from 'react';

const Plan = () => {
    const history = useHistory()
    const [isAnnual, setIsAnnual] = useState(false)

    const cardInfo = [
        {
            index: 0,
            title: 'Free',
            subtitle: 'Personal meetings',
            price: 'Free',
            features: [
                'Host up to 100 attendees',
                '1 hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: 'Try Studio - Free',
        },
        {
            index: 1,
            title: 'Pro',
            subtitle: 'Great for small teams',
            price: '7.00',
            price2: '7,000',
            features: [
                'Host up to 100 attendees',
                '30 Hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: '30-Day Free Trial'
        },
        {
            index: 2,
            title: 'Business',
            subtitle: 'Great for small businesses',
            price: '12.00',
            price2: '12,000',
            features: [
                'Host up to 300 attendees',
                '30 Hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: '30-Day Free Trial',
        },
        {
            index: 3,
            title: 'Enterprise',
            subtitle: 'Great for large businesses',
            price: 'Contact Sales',
            features: [
                'Host up to 500 - 1000 attendees',
                '30 Hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: 'Contact Sales',
        },
    ]

    const cardInfoAnnual = [
        {
            index: 0,
            title: 'Free',
            subtitle: 'Personal meetings',
            price: 'Free',
            features: [
                'Host up to 100 attendees',
                '1 hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: 'Try Studio - Free',
        },
        {
            index: 1,
            title: 'Pro',
            subtitle: 'Great for small teams',
            price: '76.00',
            price2: '70,000',
            features: [
                'Host up to 100 attendees',
                '30 Hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: '30-Day Free Trial'
        },
        {
            index: 2,
            title: 'Business',
            subtitle: 'Great for small businesses',
            price: '130.00',
            price2: '120,000',
            features: [
                'Host up to 300 attendees',
                '30 Hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: '30-Day Free Trial',
        },
        {
            index: 3,
            title: 'Enterprise',
            subtitle: 'Great for large businesses',
            price: 'Contact Sales',
            features: [
                'Host up to 500 - 1000 attendees',
                '30 Hour Time Limit',
                'Live Chat',
                'File Sharing',
                'Meeting Recording'
            ],
            link: 'Contact Sales',
        },
    ]

    const gotoCorporateSignupPage = (title) => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/corporate_signup?plan=' + title.toLowerCase();
    }

    const gotoIndividualSignupPage = (title) => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/signup' + (title === 'pro' ? '?plan=pro' : '');
    }

    return (
        <>
            <Fade right>
                <div className='main-container bg-pricing'>
                    <div className='container'>
                        <div className='parent'>
                            <h1>
                                Choose a plan for<br />you or your team
                            </h1>
                            <h3>
                                Save up to 10% on annual plans
                            </h3>
                            <div className='row d-flex button-container' style={{ justifyContent: 'center' }}>
                                <ButtonGroup aria-label="Basic example" className='tab-buttons'>
                                    <Button className={isAnnual ? 'button-unselected' : 'button-selected'} style={{ marginRight: 8 }} label='Monthly' onClick={() => setIsAnnual(false)} />
                                    <Button className={isAnnual ? 'button-selected' : 'button-unselected'} label='Annual' onClick={() => setIsAnnual(true)} />
                                </ButtonGroup>
                            </div>

                            <div className="row justify-content-center">
                                <PricingCard
                                    details={isAnnual ? cardInfoAnnual : cardInfo}
                                    isAnnual={isAnnual}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>


            <div className='container'>
                <div className='compare-section'>
                    <h3>
                        Compare <br />Plans & Pricing
                    </h3>
                    <div className='underline'>
                        <img src={underline} alt='underline' />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th><b>Free</b></th>
                                <th><b>Pro</b></th>
                                <th><b>Business</b></th>
                                <th><b>Enterprise</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-column="">
                                    <b>Participant Capacity</b>
                                    <div className='subtitle'>Participants can join your meeting for free from their phone, tablet, PC or laptop and do not need a Studio account. Each plan has a participant limit. You can increase the limit by upgrading your plan.</div>
                                </td>
                                <td data-column="Free">100</td>
                                <td data-column="Pro">100</td>
                                <td data-column="Business">300</td>
                                <td data-column="Enterprise">500</td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>License Count</b>
                                    <div className='subtitle'>Each plan has specific limits on how many users can be assigned meeting licenses.</div>
                                </td>
                                <td data-column="Free">1</td>
                                <td data-column="Pro">2</td>
                                <td data-column="Business">2</td>
                                <td data-column="Enterprise">3</td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Time Limit</b>
                                    <div className='subtitle'>The maximum duration allowed per group meeting</div>
                                </td>
                                <td data-column="Free">1 hour</td>
                                <td data-column="Pro">30 hours</td>
                                <td data-column="Business">30 hours</td>
                                <td data-column="Enterprise">30 hours</td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Simultaneous Meeting</b>
                                    <div className='subtitle'>Users can have multiple meetings at the same time on paid plans</div>
                                </td>
                                <td data-column="Free"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Pro"> 2 </td>
                                <td data-column="Business"> 2 </td>
                                <td data-column="Enterprise"> 3 </td>
                            </tr>
                            <tr>
                                <td data-column=""><b>Team Chat</b></td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column=""><b>Screensharing</b></td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column=""><b>File Sharing</b></td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column=""><b>Recording</b></td>
                                <td data-column="Free"> Unlimited cloud storage with time validity </td>
                                <td data-column="Pro"> Unlimited cloud storage with time validity </td>
                                <td data-column="Business"> Unlimited cloud storage with time validity </td>
                                <td data-column="Enterprise"> Unlimited cloud storage with time validity </td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Recording Download</b>
                                    <div className='subtitle'>Easily download your sessions recordings in mp4 format. Recordings can also be sent via emails</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Breakout Rooms</b>
                                    <div className='subtitle'>Host participants in smaller groups completely isolated in terms of audio and video from the main session</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Share External Video</b>
                                    <div className='subtitle'>Easily and quickly share external videos from YouTube, Vimeo, Instructure Media, Twitch, Dailymotion, and media file URLs without screen share. Participants can engage with shared videos</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Interactive Whiteboard</b>
                                </td>
                                <td data-column="Free">10</td>
                                <td data-column="Pro">10</td>
                                <td data-column="Business">10</td>
                                <td data-column="Enterprise">10</td>
                            </tr>
                            <tr>
                                <td data-column=""><b>Virtual Background</b></td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Personal Meeting ID</b>
                                    <div className='subtitle'>Your own permanent virtual meeting room. You can start this meeting at any time or schedule it for future use</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Shared Notes</b>
                                    <div className='subtitle'>Meeting participants can collaborate and take notes together in real-time </div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Polling</b>
                                    <div className='subtitle'>Quick and easy polling to encourage more engagement from meeting attendees. Can be done anonymously</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Random User Select</b>
                                    <div className='subtitle'>Maximise fairness and equity. Host can randomly select participants to engage during meetings</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Word Search on Playback</b>
                                    <div className='subtitle'>You can jump to specific sections of a recorded presentation by searching for keywords.</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Waiting Room</b>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Shared Rooms</b>
                                </td>
                                <td data-column="Free"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Pro"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Connection Status</b>
                                    <div className='subtitle'>Check connection status to see network strength on your device. As a host, you can see the network status of all attendees</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Co-host and Alternate Host</b>
                                    <div className='subtitle'>Share hosting privileges with another user. Co-hosts can also manage the administrative side of the meeting</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Host Controls</b>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Preload Presentations</b>
                                    <div className='subtitle'>Preload your slides before the start of each meeting and get right to it as soon as the meeting starts</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Multiple Presentation Uploads</b>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Co-annotation Screen Share</b>
                                    <div className='subtitle'>Multiple users can interact with the shared screen simultaneously, making it easier to collaborate and exchange ideas. Participants can draw, add text, highlight, and use other annotation tools to provide feedback during a meeting</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>LTI Integration</b>
                                    <div className='subtitle'>Enable seamless learning experience for students and instructors. Easily add a meeting to any course activity or access learning tools and systems from within Studio.<br />
                                        <span style={{ color: 'red' }}>***</span> works with <a href='https://ediify.app/' target='_blank'>EdiifyLMS</a></div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Multi-language Interface</b>
                                    <div className='subtitle'>Participants can navigate and access meetings in different languages</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            {/* <tr>
                                    <td data-column="">
                                        <b>Assign Meeting Scheduler</b>
                                        <div className='subtitle'>Assign someone else to set up your meetings for you. Allows you to assign someone else to set up your meetings for you</div>
                                    </td>
                                    <td data-column="Free"><img src={Mark} /></td>
                                    <td data-column="Pro"><img src={Mark} /></td>
                                    <td data-column="Business"><img src={Mark} /></td>
                                    <td data-column="Enterprise"><img src={Mark} /></td>
                                </tr> */}
                            <tr>
                                <td data-column="">
                                    <b>Reporting</b>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Phone Dail-in</b>
                                </td>
                                <td data-column="Free"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Pro"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Service Extensions</b>
                                </td>
                                <td data-column="Free"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Single Sign-on</b>
                                </td>
                                <td data-column="Free"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Pro"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Business"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column="">
                                    <b>Calendar Client</b>
                                    <div className='subtitle'>Schedule and join Studio meeting with ease</div>
                                </td>
                                <td data-column="Free"><img src={Mark} /></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            {/* <tr>
                                    <td data-column="">
                                        <b>End-to-end encryption</b>
                                    </td>
                                    <td data-column="Free"><img src={Mark} /></td>
                                    <td data-column="Pro"><img src={Mark} /></td>
                                    <td data-column="Business"><img src={Mark} /></td>
                                    <td data-column="Enterprise"><img src={Mark} /></td>
                                </tr> */}
                            <tr>
                                <td data-column="">
                                    <b>Technical support</b>
                                </td>
                                <td data-column="Free"><span style={{ fontSize: 20 }}> - </span></td>
                                <td data-column="Pro"><img src={Mark} /></td>
                                <td data-column="Business"><img src={Mark} /></td>
                                <td data-column="Enterprise"><img src={Mark} /></td>
                            </tr>
                            <tr>
                                <td data-column=""></td>
                                <td data-column="Free" className='bctn'>
                                    <div className='action'>
                                        <Button
                                            fullWidth
                                            style={{ maxWidth: '200px' }}
                                            variant='bordered'
                                            type="submit"
                                            label="Try Studio - Free"
                                            onClick={() => gotoIndividualSignupPage('free')}
                                        />
                                    </div>
                                </td>
                                <td data-column="Pro" className='bctn'>
                                    <div className='action'>
                                        <Button
                                            fullWidth
                                            style={{ maxWidth: '200px' }}
                                            variant='bordered'
                                            type="submit"
                                            label="30-Day Free Trial"
                                            onClick={() => gotoIndividualSignupPage('pro')}
                                        />
                                    </div>
                                </td>
                                <td data-column="Business" className='bctn'>
                                    <div className='action'>
                                        <Button
                                            fullWidth
                                            style={{ maxWidth: '200px' }}
                                            variant='bordered'
                                            type="submit"
                                            label="30-Day Free Trial"
                                            onClick={() => gotoCorporateSignupPage('business')}
                                        />
                                    </div>
                                </td>
                                <td data-column="Enterprise" className='bctn'>
                                    <div className='action'>
                                        <Button
                                            fullWidth
                                            style={{ maxWidth: '200px' }}
                                            variant='bordered'
                                            type="submit"
                                            label="Contact Sales"
                                            onClick={() => history.push("/contact-us")}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Plan