import React, { useState } from 'react'
import "../assets/styles/pageStyles/home.scss";

const Cookie = () => {

    return (
        <div className="main-container">
            <div class="container mb-8 mt-4">
                <div class="text-center lh-base mb-5">
                    <h1> Cookie Policy</h1>
                </div>

                <section class="lh-base mb-5">
                    <article>
                        <p>Please read this cookie policy (“cookie policy”, “policy”) carefully before using this website (“website”, “service”) operated by [EduTech] (“us”, ‘we”, “our”).</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>What are Cookies?</h2>
                    <article>
                        <p>Cookies are simple text files that are stored on your computer or mobile device by a website’s server. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>What type of cookies do we use?</h2>
                    <h3>Necessary Cookies</h3>
                    <article>
                        <p>Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h3>Functionality Cookies</h3>
                    <article>
                        <p>Functionality cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h3>Performance Cookies</h3>
                    <article>
                        <p>These are used to gather data on how users use our website and the pages visited most often. They monitor only the performance of our website as the user interacts with it. All data collected by these cookies are anonymous and are solely used to improve the functionality of our website.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>How to delete Cookies?</h2>
                    <article>
                        <p>If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting.</p>
                    </article>
                </section>

                <section class="lh-base mb-5">
                    <h2>Contacting us</h2>
                    <article>
                        <p>If you have any questions about this policy or our use of cookies, please contact us us at<br />
                            <a
                                href="mailto:dpo@edutech.global"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                dpo@edutech.global
                            </a></p>

                    </article>
                </section>

            </div>

        </div>
    )
};

export default Cookie