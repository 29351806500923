import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
// import IconExpandLess from "@material-ui/icons/ExpandLess";
// import IconExpandMore from "@material-ui/icons/ExpandMore";
// import { RiArrowDropDownLine } from "react-icons/ri";
import {
  GoChevronRight,
  GoChevronDown,
  GoChevronUp,
  GoChevronLeft,
} from "react-icons/go";
import { Collapse } from "@material-ui/core";
import Button from "./Button";

const NavMenus = ({ mobile, ...rest }) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const path = location.pathname;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const gotoHostPage = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL;
  };
  const gotoLoginPage = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/signin';
  }
  const gotoSignupPage = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/plan_selection';
  }

  const history = useHistory();

  const getSubMenu = () => {
    return (
      <div className="sub-menu row d-flex">
        <div className="col-6">
          <div className="sub-menu-title">Industry</div>

          <Link to="/solutions/education">
            <div
              className={`sub-menu-item  ${path === "/solutions/education" ? "sub-menu-item--active" : ""
                }`}
            >
              <span>Education</span>
            </div>
          </Link>

          <Link to="/solutions/healthcare">
            <div
              className={`sub-menu-item ${path === "/solutions/healthcare" ? "sub-menu-item--active" : ""
                }`}
            >
              <span>Healthcare</span>
            </div>
          </Link>

          <Link to="/solutions/government">
            <div
              className={`sub-menu-item ${path === "/solutions/government" ? "sub-menu-item--active" : ""
                }`}
            >
              <span>Government</span>
            </div>
          </Link>
        </div>
        <div className="col-6">
          <div className="sub-menu-title">Business Type</div>
          <Link to="/solutions/startups">
            <div
              className={`sub-menu-item ${path === "/solutions/startups" ? "sub-menu-item--active" : ""
                }`}
            >
              <span>Startups</span>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  const [colorChange, setColorchange] = useState(false);
  // const changeNavbarColor = () => {
  //   if (window.scrollY >= 85) {
  //     setColorchange(true);
  //   } else {
  //     setColorchange(false);
  //   }
  // };
  // window.addEventListener("scroll", changeNavbarColor);

  return (
    <div {...rest}>
      <div
        className={`nav d-flex align-items-center nav-items ${mobile ? "flex-column" : ""
          }`}
      >
        {!mobile ? (
          <div
            className="dropdown nav-text header-item"
            onClick={() => setExpanded(!expanded)}
          >
            <Link
              className={`nav-item`}
              style={{ marginRight: 0 }}
            >
              Solutions
            </Link>
            {expanded ? (
              <GoChevronUp
                size="20px"
                style={{ color: "black", paddingBottom: 2 }}
              />
            ) : (
              <GoChevronDown
                size="20px"
                style={{ color: "black", paddingBottom: 2 }}
              />
            )}
            {expanded && <div className="dropdown-menu">{getSubMenu()}</div>}
          </div>
        ) : (
          <div className="dropdown">
            <li className="nav-text"
              onClick={handleExpandClick}>
              <span
                className={`nav-item`}
              >
                Solutions{" "}
              </span>
              <span
                className="d-xs-block d-md-none"
              >
                {expanded ? (
                  <GoChevronDown size="20px" />
                ) : (
                  <GoChevronRight size="20px" />
                )}
              </span>
            </li>
            {expanded && (
              <div aria-labelledby="dropdownMenuTrigger">
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <div className="mobile-sub-menu">{getSubMenu()}</div>
                </Collapse>
              </div>
            )}
          </div>
        )}
        {!mobile ? (
          <div className="dropdown nav-text header-item">
            <Link
              to="/pricing"
              className={`nav-item ${path === "/pricing" ? "nav-item--active" : ""
                }`}
            >
              Pricing
            </Link>
          </div>
        ) : (
          <div className="dropdown">
            <li className="nav-text">
              <span
                className={`nav-item ${path === "/pricing" ? "nav-item--active" : ""
                  }`}
                onClick={() => history.push("/pricing")}
              >
                Pricing{" "}
              </span>
            </li>
          </div>
        )}

        <Link to="/join">
          <li
            className={`nav-item header-item ${path === "/join" ? "nav-item--active" : ""
              }`}
          >
            Join
          </li>
        </Link>

        {!mobile ? (
          <div className="dropdown nav-text header-item">
            <Link className={`nav-item`} onClick={gotoHostPage}>
              Host
            </Link>
          </div>
        ) : (
          <div className="dropdown">
            <li className="nav-text">
              <span className={`nav-item`} onClick={gotoHostPage}>
                Host{" "}
              </span>
            </li>
          </div>
        )}

        <Link to="/resource">
          <li
            className={`nav-item header-item ${path === "/resource" ? "nav-item--active" : ""
              }`}
          >
            Resource
          </li>
        </Link>

        <Link to="/contact-us">
          <li
            className={`nav-item header-item ${path === "/contact-us" ? "nav-item--active" : ""
              }`}
          >
            Contact
          </li>
        </Link>

        {mobile &&
          <>
            <Button label="Sign in" onClick={gotoLoginPage} variant={"outlined-colored"}
              size="nav" style={{ marginBottom: '10px' }} />
            <Button label="Try for free" variant="primary" size="nav" style={{ marginBottom: '10px' }}
              onClick={gotoSignupPage} />
          </>
        }

      </div>
    </div>
  );
};
NavMenus.propTypes = {
  mobile: PropTypes.bool,
};

export default NavMenus;
