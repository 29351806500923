import React from 'react';
import "../../assets/styles/pageStyles/faq.scss";
import SingleFAQ from '../../components/singleFAQ';
import underline from "../../assets/images/underline.svg";

const FAQ = () => {
    const faqs = [
        {
            q: "What is a Licensed user and what is a Participant?",
            a: "A licensed user is able to Schedule and host meetings while a Participant is only able to join a meeting using an Invite link."
        },
        {
            q: "How many participants can join the meeting?",
            a: "For the free plan 100 participants."
        },
        {
            q: "How many people can use one meeting license? ",
            a: "1 user license per person."
        },
        // {
        //     q: "What is the difference between the Basic and Pro plans?",
        //     a: "..."
        // },
        {
            q: "Is the Free plan always free?",
            a: "Yes the free plan is free for life."
        }
    ]



    return (
        <div className='container faq-container'>
            <h3>
                Frequently Asked<br />Questions
            </h3>
            <div className='underline'>
                <img src={underline} alt='underline' />
            </div>
            {faqs.map((faq, index) => (
                <SingleFAQ faq={faq} key={index} />
            ))}
        </div>
    )
};

export default FAQ