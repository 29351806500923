import React from "react";
import Bounce from 'react-reveal/Bounce';
import FeaturesCard from "../../components/FeaturesCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import underline from "../../assets/images/Home/underline.svg"
import freetime from "../../assets/images/Home/Freetime.webp"
import educationIcon from "../../assets/images/Home/educationIcon.png"
import startupsIcon from "../../assets/images/Home/startupsIcon.png"
import healthcareIcon from "../../assets/images/Home/healthcareIcon.png"
import governmentIcon from "../../assets/images/Home/governmentIcon.png"
import "../../assets/styles/componentStyles/features.scss";

const Features = () => {

  const responsive = {
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
      slidesToSlide: 3,
    },
  };


  const data = [
    {
      icon: educationIcon,
      tag: "EDUCATION",
      color: '#ffffff',
      bgColor: "#2B55B7",
      title: "Bridge the Learning Gap",
      content: "Keep teachers and students engaged on a platform that enhances learning and collaboration",
    },
    {
      icon: startupsIcon,
      tag: "STARTUPS",
      color: '#ffffff',
      bgColor: "#2774F3",
      title: "Improve your Workflow",
      content: "Focus on what matters - creating innovation. Studio will bring your team together with an easy-to-use collaboration platform",
    },
    {
      icon: healthcareIcon,
      tag: "HEALTHCARE",
      color: '#ffffff',
      bgColor: "#0E347E",
      title: "Streamline Healthcare delivery",
      content: "Enjoy improved patient engagement and enhanced collaboration among health teams",
    },
    {
      icon: governmentIcon,
      tag: "GOVERNMENT",
      color: '#000000',
      bgColor: "#B9CFF9",
      title: "Drive Change Through Engagement",
      content: "Enhance the involvement of agencies and citizens through collaborative experiences that are adaptable and secure",
    }
  ];

  return (
    <div className="card-feature-BG">
      <div className="feature-container feature-BG">
        <Bounce left>
          <div className="container">
            <div className="d-none d-lg-flex row align-items-center">
              <div className="col-12 col-lg-5">
                <div className="feature-text">
                  Discover <br />tailor-made <br />solutions for <br />your industry
                </div>
                <div className='underline'>
                  <img src={underline} alt='underline' />
                </div>
                <div className='tag-left'>
                  <img src={freetime} alt='tag-left' />
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="row statistic-column">
                  {data.map((stat, index) => (
                    <div className="individual-card"
                      style={{ paddingTop: index === 1 ? '4rem' : index === 3 ? '4rem' : 0, marginLeft: index === 1 ? '-4rem' : index === 3 ? '-4rem' : 0 }}
                      key={index}>
                      <FeaturesCard data={stat} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex d-lg-none row align-items-center">
              <div className="col-12 col-lg-5">
                <div className="feature-text">
                  Discover tailor-made solutions for your industry
                </div>
                <div className='underline'>
                  <img src={underline} alt='underline' />
                </div>
                <div className='tag-left'>
                  <img src={freetime} alt='tag-left' />
                </div>
              </div>
              <div className="col-12 col-lg-7">
                <div className="row statistic-column">
                  {data.map((stat, index) => (
                    <div className="individual-card"
                      // style={{ paddingTop: index === 1 ? '4rem' : index === 3 ? '4rem' : 0, marginLeft: index === 1 ? '-1rem' : index === 3 ? '-1rem' : 0 }}
                      style={{ paddingTop: index === 0 ? '0' : index === 1 ? '4rem' : 0, marginLeft: index === 1 ? '-1rem' : index === 3 ? '-1rem' : 0, marginTop: index === 2 ? '-5rem' : index === 3 ? '-1rem' : 0 }}
                      key={index}>
                      <FeaturesCard data={stat} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <div
              className="d-block d-lg-none space-top-1 space-bottom-2 "
            >
              <Carousel
                responsive={responsive}
                arrows={false}
                slidesToSlide={3}
                partialVisible={true}
                showDots={true}
                dotListClass="slider-dots"
              >

                {data.map((stat, index) => (
                  <div
                    key={index}
                    className="space-bottom-2"
                  >
                    <FeaturesCard data={stat} key={index} />
                  </div>
                ))}
              </Carousel>
            </div> */}
          </div>
        </Bounce>
      </div>
    </div>
  );
};

export default Features;
