import React from "react";
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import "../../assets/styles/pageStyles/home.scss";
import partner1 from "../../assets/images/Partners/babcock.png";
import partner2 from "../../assets/images/Partners/ga.png";
import partner3 from "../../assets/images/Partners/tap.png";
import partner4 from "../../assets/images/Partners/edutech.png";
import partner5 from "../../assets/images/Partners/partner-logo.png";
import partner6 from "../../assets/images/Partners/roberttaylor.png";
import partner7 from "../../assets/images/Partners/vgg.png";
import partner8 from "../../assets/images/Partners/AccurateHRS.jpeg";
import partner9 from "../../assets/images/Partners/AmericanCorner.jpeg";
import partner11 from "../../assets/images/Partners/GreenHouseCap.png";
import partner12 from "../../assets/images/Partners/Original-ASLI-logo.png";
import partner13 from "../../assets/images/Partners/PowerTech.png";
import partner14 from "../../assets/images/Partners/Technest.png";
import partner15 from "../../assets/images/Partners/Vigipay.png";
import partner16 from "../../assets/images/Partners/ZenithMedical.png";
import underline from "../../assets/images/underline.svg";


const Partners = () => {
    const partnerLogos = [
        partner1,
        partner2,
        partner7,
        partner6,
        partner3,
        partner4,
        partner5,
        partner8,
        partner9,
        partner11,
        partner12,
        partner13,
        partner14,
        partner15,
        partner16,
    ];
    return (
        <div className="home-partners-wrapper">
            <div className="container home-partners">
                <h1 className="darker-text text-center">
                    Trusted by<br /> <span className="dark-text-alt"> Businesses & People</span>
                </h1>
                <div className='underline'>
                    <img src={underline} alt='underline' />
                </div>
                {/* <div className="home-partners-logos">
                    {partnerLogos.map((partner, index) => (
                        <div key={index}>
                            <div
                                className="partner-logo"
                                style={{ backgroundImage: `url(${partner})` }}
                            ></div>
                        </div>
                    ))}
                </div> */}
                <Swiper
                    slidesPerView={window.innerWidth <= 760 ? 3 : 5}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                >
                    {partnerLogos.map((partner, index) => (
                        <div key={index}>
                            <SwiperSlide><div
                                className="partner-logo"
                                style={{ backgroundImage: `url(${partner})` }}
                            ></div></SwiperSlide>
                        </div>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default Partners;
