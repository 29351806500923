import React, { useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import HomeBanner from "../Home/HomeBanner";
import Partners from "../Home/Partners";
import Testimonials from "../Home/Testimonials";
import GetStarted from "../Home/GetStarted";
import rightImage from "../../assets/images/Solutions/education.png"
import FeaturesAlt from "./FeaturesAlt";
import Experience from "./Experience";
import experience1 from "../../assets/images/Solutions/remote-experience.png";
import experience2 from "../../assets/images/Solutions/accessibility-experience.png";
import experience3 from "../../assets/images/Solutions/flexible-experience.png";
import experience4 from "../../assets/images/Solutions/recording-experience.png";
import experience5 from "../../assets/images/Solutions/collaboration-experience.png";
import experience12 from "../../assets/images/Solutions/remote-experience-xs.png";
import experience22 from "../../assets/images/Solutions/accessibility-experience-xs.png";
import experience32 from "../../assets/images/Solutions/flexible-experience-xs.png";
import experience42 from "../../assets/images/Solutions/recording-experience-xs.png";
import experience52 from "../../assets/images/Solutions/collaboration-experience-xs.png";

const Education = () => {

  const experiences = [
    {
      bgColor: '#2B55B7',
      image1: experience1,
      image2: experience12,
      title: 'Remote Learning',
      caption: 'Create a virtual classroom experience for students to access educational resources from anywhere in the world. Share your screen, use the dynamic whiteboard and engage in real-time discussions.'
    },
    {
      bgColor: '#EAEEF8',
      image1: experience5,
      image2: experience52,
      title: 'Collaboration',
      caption: 'Students can collaborate with their peers on projects and assignments in real-time, enhancing teamwork, socialisation and improving learning outcomes. You can use Studio’s breakout rooms to divide into groups.'
    },
    {
      bgColor: '#712BB7',
      image1: experience4,
      image2: experience42,
      title: 'Recording and Playback',
      caption: "Did a student miss a class? That’s no problem. Studio allows for recording, playback and download of class sessions."
    },
    {
      bgColor: '#EAEEF8',
      image1: experience3,
      image2: experience32,
      title: 'Flexible Scheduling',
      caption: 'Make it easier for teachers to schedule classes and for students to attend them from anywhere; as long as they have an internet connection.'
    },
    {
      bgColor: '#0CA17D',
      image1: experience2,
      image2: experience22,
      title: 'Accessibility',
      caption: 'Studio can make education more accessible to students with disabilities, who may require assistive technologies or accommodations to participate in traditional classroom settings.'
    },
  ];
  return (
    <>
      <HomeBanner
        caption="Solutions for Education"
        first_title="Bridge the"
        second_title="learning gap."
        details="Introduce ease and flexibility to your school.
        Facilitate remote and interactive learning modalities for your students. Studio is the platform you need to enhance collaboration and engagement between students, teachers and school administrators."
        isShowButton={false}
        image={rightImage}
      />
      <Experience
        experiences={experiences}
        first_title="The Virtual"
        second_title="Classroom Experience"
      />
      <Partners />
      {/* <Testimonials /> */}
      <FeaturesAlt />
      <GetStarted />
    </>
  );
};

export default Education;
