import React, { useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import HomeBanner from "../Home/HomeBanner";
import Partners from "../Home/Partners";
import Testimonials from "../Home/Testimonials";
import GetStarted from "../Home/GetStarted";
import rightImage from "../../assets/images/Solutions/startups.png"
import FeaturesAlt from "./FeaturesAlt";
import Experience from "./Experience";
import experience1 from "../../assets/images/Solutions/team-bonding.png";
import experience2 from "../../assets/images/Solutions/improved-collaboration.png";
import experience3 from "../../assets/images/Solutions/more-flexibility.png";
import experience12 from "../../assets/images/Solutions/team-bonding-xs.png";
import experience22 from "../../assets/images/Solutions/improved-collaboration-xs.png";
import experience32 from "../../assets/images/Solutions/more-flexibility-xs.png";

const Startups = () => {

  const experiences = [
    {
      bgColor: '#2B55B7',
      image1: experience1,
      image2: experience12,
      title: 'Enhanced Team Bonding',
      caption: 'Create a sense of community and promote team bonding, even when employees are working remotely.'
    },
    {
      bgColor: '#EAEEF8',
      image1: experience2,
      image2: experience22,
      title: 'Improved Collaboration',
      caption: 'A virtual workspace for your team to work together on projects regardless of location or device. With Studio’s multi-user whiteboard, shared notes, multi-format document support, screen sharing and a host of other collaboration tools, your team is equipped for success.'
    },
    {
      bgColor: '#712BB7',
      image1: experience3,
      image2: experience32,
      title: 'More Flexibility',
      caption: "Offer your employees more flexible work arrangements, including remote work options. Save time and resources in your hiring process. Use Studio to attract talent from anywhere in the world."
    },
  ];
  return (
    <>
      <HomeBanner
        caption="Solutions for startups"
        first_title="Focus on"
        second_title="Innovation."
        details="Increase communication, reduce expenses, and enhance team collaboration, leading to improved productivity and faster growth."
        isShowButton={false}
        image={rightImage}
      />
      <Experience
        experiences={experiences}
        first_title="The Agile"
        second_title="Experience"
      />
      <Partners />
      {/* <Testimonials /> */}
      <FeaturesAlt />
      <GetStarted />
    </>
  );
};

export default Startups;
