import React from "react";
import { useLocation } from "react-router-dom";
import "../../assets/styles/pageStyles/classroom.scss";
import toptag from "../../assets/images/Home/solution-left.png"
import bottomtag from "../../assets/images/Home/solution-right.png"

const Classrooms = ({ index, bgColor, icon, title, caption, Image1, Image2, rightAlignment }) => {

    const location = useLocation();
    const path = location.pathname;

    return (
        <>
            {rightAlignment ?
                <>
                    <div className="md-block">
                        <div className="experience-card" style={{ background: bgColor, borderRadius: '0 80px 80px 0' }}>
                            <div className="row align-items-center about-card goal-text-BG">
                                <div className="goal-text" style={{ color: '#ffffff', marginLeft: '15%' }}>
                                    <img src={icon} alt="additem" />
                                    <h3>{title}</h3>
                                    <div style={{ fontWeight: '600', fontSize: '1.5rem' }}>
                                        {caption}
                                    </div>
                                </div>
                                <div className="goal-image-container">
                                    <img src={Image1} alt="Goal" width="auto" className="right" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="xs-none">
                        <div className="feature-card" style={{ background: bgColor, borderRadius: '0 58px 58px 0', margin: '3rem 1.5rem 3rem 0' }}>
                            <div className="row align-items-center about-card goal-text-BG">
                                <div className="goal-text">
                                    <div className="d-flex justify-content-space-between align-items-center">
                                        <img src={icon} alt="additem" className="add" />
                                        <h3>{title}</h3>
                                    </div>
                                    <div style={{ fontWeight: '600', fontSize: '1rem' }}>
                                        {caption}
                                    </div>
                                </div>
                                <div className="goal-image-container-xs">
                                    <img src={Image2} alt="Goal" className="right" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: '58px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="md-block">
                        <div className="solution-card">
                            <div className="solution-top-tag d-none d-lg-block">
                                <img src={toptag} />
                            </div>
                            <div className="experience-card" style={{ background: bgColor, borderRadius: '80px 0 0 80px', marginLeft: '10%' }}>
                                <div className="row align-items-center about-card">
                                    <div className="goal-image-container" style={{ textAlign: 'start' }}>
                                        <img src={Image1} alt="Goal" width="auto" className="left" />
                                    </div>
                                    <div className="goal-text" style={{ color: '#000000', marginRight: '15%' }}>
                                        <img src={icon} alt="additem" />
                                        <h3>{title}</h3>
                                        <div style={{ fontWeight: '600', fontSize: '1.5rem' }}>
                                            {caption}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(path !== '/solutions/government' || index !== 3) && <div className="solution-bottom-tag d-none d-lg-block">
                                <img src={bottomtag} />
                            </div>}
                        </div>
                    </div>
                    <div className="xs-none">
                        <div className="feature-card" style={{ background: bgColor, borderRadius: '58px 0 0 58px', margin: '3rem 0 3rem 1.5rem' }}>
                            <div className="row align-items-center about-card goal-text-BG" style={{ backgroundPositionY: '-4rem' }}>
                                <div className="goal-text" style={{ color: "black" }}>
                                    <div className="d-flex justify-content-space-between align-items-center">
                                        <img src={icon} alt="additem" className="add" />
                                        <h3>{title}</h3>
                                    </div>
                                    <div style={{ fontWeight: '600', fontSize: '1rem' }}>
                                        {caption}
                                    </div>
                                </div>
                                <div className="goal-image-container-xs">
                                    <img src={Image2} alt="Goal" className="right" style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: '58px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Classrooms;
