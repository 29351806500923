import React, { useState } from 'react'
import * as Yup from "yup";
import axios from "axios";
import { RiCloseFill } from "react-icons/ri";
import { Formik, Form } from "formik";

import Button from "../components/Button";
import google from "../assets/images/google-icon.svg";
import "../assets/styles/pageStyles/login.scss";
import { Link } from 'react-router-dom';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [password2, setPassword2] = useState('');
    const [alert, setAlert] = useState({
        variant: "",
        msg: "",
    });

    const removeAlert = () => setAlert({ msg: "", variant: "" });

    const initialValues = {
        email: "",
        password: "",
    };

    const makeAlert = (msg, variant) => {
        setLoading(false);
        setAlert({ msg, variant });
        setTimeout(() => removeAlert(), 5000);
    };

    const onSubmit = async ({ email, password }, resetForm) => {
        setLoading(true);
        try {
            const payload = {
                email: email,
                password: password
            };
            const res = await axios.post(
                ``,
                payload
            );
            if (res.data.requestSuccessful === true) {
                resetForm();
                makeAlert("Logged in successfully", "success");
            } else {
                makeAlert("An error occured", "danger");
            }
        } catch (err) {
            console.log(err);
        }
    };
    const validationSchema = Yup.object({


        email: Yup.string()
            .email("Invalid email format!")
            .required("Email is empty"),

        password: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
            .min(8, "Not less than 8 characters")
            .required("Password is empty"),

        password2: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    return (
        <div className="main">
            <div className='container parent'>
                <h2> Sign Up </h2>
                <div style={{ marginTop: '30px' }}>
                    <div className='auth-box'>
                        <img src={google} alt="google icon" /> Sign up with Google
                    </div>
                </div>
                <h3> OR </h3>
                <div className='inputs'>
                    {alert.msg !== "" && (
                        <div
                            class={`alert alert-${alert.variant} fade show mb-4 d-flex justify-content-between `}
                            role="alert"
                        >
                            {alert.msg}
                            <RiCloseFill
                                className="icon"
                                size="20px"
                                style={{ cursor: "pointer" }}
                                onClick={() => removeAlert()}
                            />
                        </div>
                    )}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) =>
                            onSubmit(values, resetForm)
                        }
                    >
                        {({ errors, touched, getFieldProps, values }) => (
                            <Form noValidate autoComplete="off">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`form-control input-field ${errors.email && touched.email ? "form-error" : ""
                                            }`}
                                        name="email"
                                        {...getFieldProps("email")}
                                        id="email"
                                        placeholder="Email"
                                    />
                                    <small className="text-danger">
                                        {errors.email && touched.email && errors.email}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <input
                                        type="password"
                                        className={`form-control input-field ${errors.password && touched.password ? "form-error" : ""
                                            }`}
                                        name="password"
                                        {...getFieldProps("password")}
                                        id="password"
                                        placeholder="Password"
                                    />
                                    <small className="text-danger">
                                        {errors.password && touched.password && errors.password}
                                    </small>
                                </div>

                                <div className="form-group">
                                    <input
                                        type="password"
                                        className={`form-control input-field ${errors.password2 && touched.password2 ? "form-error" : ""
                                            }`}
                                        name="password2"
                                        {...getFieldProps("password2")}
                                        id="password2"
                                        placeholder="Repeat Password"
                                    />
                                    <small className="text-danger">
                                        {errors.password2 && touched.password2 && errors.password2}
                                    </small>
                                </div>

                                <div className="submit">
                                    <Button
                                        disabled={loading}
                                        variant="tetiary"
                                        type="submit"
                                        label="Log in"
                                        disabledText="Submitting..."
                                    />
                                </div>

                                <div className='terms'>
                                    Already have a Studio account? <Link to="/login"><span style={{ color: '#407bff' }}> Log In </span></Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
};

export default Login