import React from "react";
import AddIcon from "../assets/images/Home/additem.svg";
import "../assets/styles/pageStyles/about.scss";

const AboutCard = (props) => {

    return (
        <>
            <div className="md-block">
                <div className={props.classes}>
                    <div className="feature-card" style={{ background: props.bgColor }}>
                        <div className="row align-items-center about-card goal-text-bg">
                            <div className="goal-text">
                                <img src={AddIcon} alt="add item" />
                                <h3>{props.title}</h3>
                                <div style={{ fontWeight: '600', fontSize: '1.5rem' }}>
                                    {props.caption}
                                </div>
                            </div>
                            <div className="goal-image-container">
                                <img src={props.Image1} alt="Goal" height='100%' />
                                {/* <ImageWebp
                                    // srcWebp=
                                    src={props.Image1}
                                    alt="Goal"
                                    height='100%'
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="xs-none">
                <div className={props.classes}>
                    <div className="feature-card" style={{ background: props.bgColor }}>
                        <div className="align-items-center about-card goal-text-bg">
                            <div className="goal-text">
                                <div className="d-flex justify-content-space-between align-items-center mb-2">
                                    <img src={AddIcon} alt="add item" className="add" />
                                    <h3>{props.title}</h3>
                                </div>
                                <div style={{ fontWeight: '600', fontSize: '1rem' }}>
                                    {props.caption}
                                </div>
                            </div>
                            <div className="goal-image-container-xs">
                                <img src={props.Image2} alt="Goal" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default AboutCard;
