import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import AuthPage from "../pages/AuthPage";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Join from "../pages/Join";
import Pricing from "../pages/Pricing";
import NotFound from "../pages/NotFound";
import ContactUs from "../pages/ContactUs";
import Privacy from "../pages/Privacy";
import TermsOfUse from "../pages/TermsOfUse";

import ScrollToTop from "./ScrollToTop";
import Government from "../pages/Solutions/Government";
import Healthcare from "../pages/Solutions/Healthcare";
import Education from "../pages/Solutions/Education";
import Startups from "../pages/Solutions/Startups";
import Cookie from "../pages/Cookie";
import Resources from "../pages/Resources";

const Routes = () => {
    const routes = [
        {
            path: "/",
            component: Home,
        },
        {
            path: "/auth",
            component: AuthPage,
        },
        {
            path: "/login",
            component: Login,
        },
        {
            path: "/signup",
            component: Signup,
        },
        {
            path: "/join",
            component: Join,
        },
        {
            path: "/pricing",
            component: Pricing,
        },
        {
            path: "/solutions/education",
            component: Education,
        },
        {
            path: "/solutions/healthcare",
            component: Healthcare,
        },
        {
            path: "/solutions/government",
            component: Government,
        },
        {
            path: "/solutions/startups",
            component: Startups,
        },
        {
            path: "/resource",
            component: Resources,
        },
        {
            path: "/contact-us",
            component: ContactUs,
        },
        {
            path: "/privacy-policy",
            component: Privacy,
        },
        {
            path: "/cookie-policy",
            component: Cookie,
        },
        {
            path: "/terms-of-use",
            component: TermsOfUse,
        },
        {
            path: "",
            component: NotFound,
        },
    ];
    return (
        <div>
            <ScrollToTop />
            <Switch>
                {routes.map(({ path, component }, key) => (
                    <Route path={path} component={component} exact key={key} />
                ))}
            </Switch>
        </div>
    );
};

export default Routes;
