import React, { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { RiCloseFill } from "react-icons/ri";
import { Formik, Form } from "formik";

import Button from "../components/Button";
import "../assets/styles/pageStyles/contactus.scss";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    variant: "",
    msg: "",
  });

  const removeAlert = () => setAlert({ msg: "", variant: "" });

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    subject: "",
    message: "",
  };

  const makeAlert = (msg, variant) => {
    setLoading(false);
    setAlert({ msg, variant });
    setTimeout(() => removeAlert(), 5000);
  };

  const onSubmit = async (
    { email, fname, lname, subject, message },
    resetForm
  ) => {
    setLoading(true);
    try {
      const payload = {
        first_name: fname,
        last_name: lname,
        email: email,
        subject: subject,
        description: message,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_REDIRECT_URL}api/forms`,
        payload
      );
      console.log(res);
      if (res.status === 200) {
        resetForm();
        window.scrollTo(0, 0);
        makeAlert(
          "Message sent successfully. Our support team will reach out to you shortly.",
          "success"
        );
      } else {
        makeAlert("An error occured", "danger");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const validationSchema = Yup.object({
    fname: Yup.string()
      .test({
        fname: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "name",
              })
            : true;
        },
      })
      .min(3, "Not less than 3 characters")
      .required("First name is empty"),

    lname: Yup.string()
      .test({
        fname: "validator-custom-name",
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "name",
              })
            : true;
        },
      })
      .min(3, "Not less than 3 characters")
      .required("Last name is empty"),

    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),

    subject: Yup.string()
      .min(1, "Not less than 1 character")
      .required("Subject is empty"),

    message: Yup.string()
      .min(30, "Not less than 30 characters")
      .max(300, "Not more than 300 characters")
      .required("Message is empty"),
  });

  return (
    <div className="contact-intro">
      <div className="row d-flex align-items-center justify-content-center container m-auto">
        <div className="col-12 col-md-6 contact-text">
          <h1>The future of learning & teamwork</h1>
          <div className="caption">
            Fill the form to make enquiries or request a demo. A representative
            will respond promptly.
          </div>
          <p>
            <div className="heading"> United States Office</div>
            24285 Katy Freeway Suite 300, Katy 77494,
            <br />
            Texas, U.S.
          </p>
          <p>
            <div className="heading">United Arab Emirates (UAE) Office</div>
            Dubai Digital Park, A5 Building DTEC, <br />
            Dubai Silicon Oasis, Dubai, UAE.
          </p>
          <p>
            <div className="heading">Nigeria Office</div>
            Vibranium Valley,
            <br />
            42, Local Airport Road, Ikeja, Lagos
          </p>
          <p>
            <div className="heading"> Partner Enquiry</div>
            +1 (512) 270 7092 <br />
            <a href="mailto:clientservice@studioapp.net">
              clientservice@studioapp.net
            </a>
          </p>
          <p>
            <div className="heading"> Support</div>
            +1 (512) 270 7092 <br />
            +234 (703) 900 6697 <br />
            <a href="mailto:support@studioapp.net">support@studioapp.net</a>
          </p>
        </div>
        <div className="col-12 col-md-6 contact-form">
          <div className="form-container card-hover">
            <div className="row">
              <div className="col-md-12">
                {alert.msg !== "" && (
                  <div
                    class={`alert alert-${alert.variant} fade show mb-4 d-flex justify-content-between `}
                    role="alert"
                  >
                    {alert.msg}
                    <RiCloseFill
                      className="icon"
                      size="20px"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeAlert()}
                    />
                  </div>
                )}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) =>
                    onSubmit(values, resetForm)
                  }
                >
                  {({ errors, touched, getFieldProps }) => (
                    <Form noValidate autoComplete="off">
                      <div className="row">
                        <div className="form-group col-12 col-md-6">
                          <label htmlFor="fname" className="input-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control input-field ${
                              errors.fname && touched.fname ? "form-error" : ""
                            }`}
                            name="fname"
                            {...getFieldProps("fname")}
                            id="fname"
                            placeholder="First Name"
                          />
                          <small className="text-danger">
                            {errors.fname && touched.fname && errors.fname}
                          </small>
                        </div>
                        <div className="form-group  col-12 col-md-6">
                          <label htmlFor="lname" className="input-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control input-field ${
                              errors.lname && touched.lname ? "form-error" : ""
                            }`}
                            name="lname"
                            {...getFieldProps("lname")}
                            id="lname"
                            placeholder="Last Name"
                          />
                          <small className="text-danger">
                            {errors.lname && touched.lname && errors.lname}
                          </small>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email" className="input-label">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control input-field ${
                            errors.email && touched.email ? "form-error" : ""
                          }`}
                          name="email"
                          {...getFieldProps("email")}
                          id="email"
                          placeholder="Email"
                        />
                        <small className="text-danger">
                          {errors.email && touched.email && errors.email}
                        </small>
                      </div>
                      <div className="form-group">
                        <label htmlFor="subject" className="input-label">
                          Subject <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control input-field ${
                            errors.subject && touched.subject
                              ? "form-error"
                              : ""
                          }`}
                          name="subject"
                          {...getFieldProps("subject")}
                          id="subject"
                          placeholder="E.g Facilitator enquiry"
                        />
                        <small className="text-danger">
                          {errors.subject && touched.subject && errors.subject}
                        </small>
                      </div>

                      <div className="form-group">
                        <label htmlFor="message" className="input-label">
                          How Can We help you?{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          type="text"
                          rows={5}
                          className={`form-control ${
                            errors.message && touched.message
                              ? "form-error"
                              : ""
                          }`}
                          name="messagee"
                          {...getFieldProps("message")}
                          id="message"
                          placeholder="Enter message here..."
                        />
                        <small>This Field is limited to 300 characters</small>
                        <small className="text-danger d-block">
                          {errors.message && touched.message && errors.message}
                        </small>
                      </div>
                      <div className="submit">
                        <Button
                          disabled={loading}
                          variant="primary"
                          type="submit"
                          label="Submit"
                          disabledText="Submitting..."
                          style={{ float: "right" }}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
