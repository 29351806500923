import React, { useState } from "react";
import "../../assets/styles/pageStyles/home.scss";
import HomeBanner from "../Home/HomeBanner";
import Partners from "../Home/Partners";
import Testimonials from "../Home/Testimonials";
import GetStarted from "../Home/GetStarted";
import rightImage from "../../assets/images/Solutions/government.png"
import FeaturesAlt from "./FeaturesAlt";
import Experience from "./Experience";
import experience1 from "../../assets/images/Solutions/community-engagement.png";
import experience2 from "../../assets/images/Solutions/increased-efficiency.png";
import experience3 from "../../assets/images/Solutions/cost-savings.png";
import experience4 from "../../assets/images/Solutions/security.png";
import experience12 from "../../assets/images/Solutions/community-engagement-xs.png";
import experience22 from "../../assets/images/Solutions/increased-efficiency-xs.png";
import experience32 from "../../assets/images/Solutions/cost-savings-xs.png";
import experience42 from "../../assets/images/Solutions/security-xs.png";

const Government = () => {

  const experiences = [
    {
      bgColor: '#2B55B7',
      image1: experience1,
      image2: experience12,
      title: 'Community Engagement',
      caption: 'Host and engage your community or constituents with meetings, conferences, and training sessions. Bring governance and public service closer to the people.'
    },
    {
      bgColor: '#EAEEF8',
      image1: experience2,
      image2: experience22,
      title: 'Increased Efficiency',
      caption: 'Enable faster decision-making processes and collaboration between agencies and the populace with Studio’s easy-to-use communication tools.'
    },
    {
      bgColor: '#712BB7',
      image1: experience3,
      image2: experience32,
      title: 'Cost Savings',
      caption: "By using Studio for virtual meetings and conferences, agencies can save money on travel and accommodation expenses, venue rentals, and other associated costs."
    },
    {
      bgColor: '#EAEEF8',
      image1: experience4,
      image2: experience42,
      title: 'Security',
      caption: 'Studio provides state-of-the-art security features, guaranteeing the utmost confidentiality and privacy for government meetings and communications.'
    },
  ];
  return (
    <>
      <HomeBanner
        caption="Solutions for government"
        first_title="Deliver"
        second_title="Change."
        details="Introduce ease and flexibility to your school.
        Facilitate remote and interactive learning modalities for your students. Studio is the platform you need to enhance collaboration and engagement between students, teachers and school administrators."
        isShowButton={false}
        image={rightImage}
      />
      <Experience
        experiences={experiences}
        first_title="The Engaging"
        second_title="Experience"
      />
      <Partners />
      {/* <Testimonials /> */}
      <FeaturesAlt />
      <GetStarted />
    </>
  );
};

export default Government;
