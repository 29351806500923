
import React from "react";
import Classrooms from "./Classrooms";
import underline from "../../assets/images/Solutions/underline.svg";
import AddIcon from "../../assets/images/Home/additem.svg";
import AddIconAlt from "../../assets/images/Home/additemAlt.svg";


const Experience = ({ experiences, first_title, second_title }) => {
    return (
        <div className="home-partners-wrapper experience-bg" style={{ paddingBottom: '2rem' }}>
            {/* <div className="home-partners"> */}
                <h1 className="darker-text text-center">
                    {first_title} <br /> <span className="dark-text-alt"> {second_title}</span>
                </h1>
                <div className='underline'>
                    <img src={underline} alt='underline' />
                </div>
                {experiences.map((experience, index) => (
                    <Classrooms
                        key={index}
                        index={index}
                        icon={index % 2 ? AddIconAlt : AddIcon}
                        rightAlignment={index % 2 ? false : true}
                        bgColor={experience.bgColor}
                        Image1={experience.image1}
                        Image2={experience.image2}
                        title={experience.title}
                        caption={experience.caption}
                    />
                ))}
            {/* </div> */}
        </div>
    );
};

export default Experience;
