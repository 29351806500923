import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  // useScrollTrigger,
  // Slide,
} from "@material-ui/core";
import { RiMenuLine } from "react-icons/ri";
import { RiCloseFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import studioLight from "../assets/images/logo-white.png";
import studioLogo from "../assets/images/logo_blue.png";
import NavMenus from "./NavMenus";
import Button from "./Button";

// const HideOnScroll = (props) => {
//   const { children } = props;

//   const trigger = useScrollTrigger();

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// };

const Header = (props) => {
  const location = useLocation();
  const path = location.pathname;

  const { toggleMobileNav, open, isLight, isBlueHeader, ...rest } = props;
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 85) {
      setColorchange(true);
    }
    else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const gotoLoginPage = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/signin';
  }

  const gotoSignupPage = () => {
    window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/plan_selection';
  }

  return (
    // <HideOnScroll {...props}>
    <AppBar
      position="fixed"
      // style={{ zIndex: 1400 }}
      className={`root`}
      style={{ background: 'white' }}
      {...rest}
    >
      <Toolbar disableGutters className="h-100">
        <div className="container d-flex justify-content-between d-flex align-items-center">
          <div>
            <Link to="/">
              <img
                src={studioLogo}
                width="132px"
                alt="Studio"
              />
            </Link>
          </div>

          <Hidden smDown>
            <NavMenus />
          </Hidden>
          <Hidden smDown>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
              <Button label="Sign in" onClick={gotoLoginPage} variant={"outlined-colored"}
                size="nav" style={{ marginRight: '15px' }} />

              <Button label="Try for free" variant="primary" size="nav" style={{ margin: '8px auto' }} onClick={gotoSignupPage} />
            </div>
          </Hidden>
          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileNav}
            >
              {open ? (
                <RiCloseFill
                  size="25px"
                  color="#3a434b"
                />
              ) : (
                <RiMenuLine
                  size="28px"
                  color="#3a434b"
                />
              )}
            </IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
    // </HideOnScroll>
  );
};

Header.propTypes = {
  toggleMobileNav: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLight: PropTypes.bool,
};

export default Header;
