import React from "react";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Engage from "../../assets/images/Home/Engage.webp";
import Meet from "../../assets/images/Home/Meet.webp";
import Collaborate from "../../assets/images/Home/Collaborate.webp";
import Share from "../../assets/images/Home/Share.webp";
import Engagexs from "../../assets/images/Home/Engage-xs.webp";
import Meetxs from "../../assets/images/Home/Meet-xs.webp";
import Collaboratexs from "../../assets/images/Home/Collaborate-xs.webp";
import Sharexs from "../../assets/images/Home/Share-xs.webp";
import engagex from "../../assets/images/Home/Engage-xs.webp";
import meetx from "../../assets/images/Home/meetx.svg";
import collaboratex from "../../assets/images/Home/collaboratex.svg";
import sharex from "../../assets/images/Home/sharex.svg";
import "../../assets/styles/pageStyles/about.scss";
import AboutCard from "../../components/AboutCard";
import { useEffect } from "react";
import { useState } from "react";

const AboutUs = () => {

  const features = [
    {
      name: 'Meet',
      image1: Meet,
      image2: Meetxs,
      position: meetx,
      bgColor: '#2B55B7',
      title: 'Connected With the World',
      caption: 'Your own virtual meeting room. Create a unique meeting link to invite attendees to your own private meeting space.'
    },
    {
      name: 'Engage',
      image1: Engage,
      image2: Engagexs,
      position: engagex,
      bgColor: '#2B9EB7',
      title: 'Interact Freely',
      caption: 'Use chat, audio and video calls, polls, and random speaker selection to interact and generate ideas with your audience in real-time. Emojis, GIFs, and stickers let you give your chats more personality.'
    },
    {
      name: 'Collaborate',
      image1: Collaborate,
      image2: Collaboratexs,
      position: collaboratex,
      bgColor: '#712BB7',
      title: 'Teamwork makes the dream work.',
      caption: 'Work together on projects and documents in real-time with the multi-user whiteboard, shared notes and Co-annotation screen share.'
    },
    {
      name: 'Share',
      image1: Share,
      image2: Sharexs,
      position: sharex,
      bgColor: '#0CA17D',
      title: 'Everyone in the Loop',
      caption: 'Easily share information and resources with team members or audiences. You can share files, links, and entire channels with your team or audience.'
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(2);
  const [isClicked, setisClicked] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === features.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000); // Change the delay here for different fade durations

    return () => {
      clearInterval(timer);
    };
  }, [features]);

  return (
    <div className="about-bg">
      <div className="our-goal container">
        <div className="row align-items-center heading">
          {features.map((feature, index) => (
            <span key={index} className={`title ${index === currentIndex ? 'active' : ''}`} onClick={() => setCurrentIndex(index)}
            > {feature.name} </span>
          ))}
        </div>

        {features.map((feature, index) => (
          <AboutCard
            classes={`slider-card ${index === currentIndex ? 'active' : 'inactive'}`}
            bgColor={feature.bgColor}
            Image1={feature.image1}
            Image2={feature.image2}
            title={feature.title}
            caption={feature.caption}
          />
        ))}

        {features.map((feature, index) => (
          <div key={index} className={`row align-items-center position slider-card ${index === currentIndex ? 'active' : 'inactive'}`}
          ><img src={feature.position} alt="feature" /> </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
