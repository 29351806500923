import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Tick from "../assets/images/tick-button.svg";
import Goto from "../assets/images/Goto.svg";
import Checked from "../assets/images/mark-check.svg";
import "../assets/styles/pageStyles/plan.scss";
import Button from "../components/Button";

const PricingCard = ({ details, isAnnual }) => {
    const history = useHistory()
    const [currentLocation, setcurrentLocation] = useState("NG")
    const gotoCorporateSignupPage = (title) => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/corporate_signup?plan=' + title.toLowerCase();
    }

    const gotoIndividualSignupPage = (title) => {
        window.location.href = process.env.REACT_APP_REDIRECT_URL + 'users/signup' + (title === 'pro' ? '?plan=pro' : '');
    }

    useEffect(() => {
        const location = localStorage.getItem("currentLocation")
        if (location) {
            setcurrentLocation(location)
        }
    }, [])

    return (
        <>
            {/* <Fade right> */}
            {details.length > 0 && details.map((info, index) => (
                <div key={index} className='col-12 col-md-3 col-sm-6'>
                    <div className='card'>
                        {index === 2 &&
                            <div className='checked'>
                                <img src={Checked} alt='mark-check' />
                            </div>
                        }
                        <h4 style={{ color: '#ffffff' }}>
                            {info.title}
                        </h4>
                        <div className='caption'>
                            {info.subtitle}
                        </div>
                        <h2>
                            {info.price !== 'Free' ? (
                                <div className='row d-flex' style={{ color: 'inherit', justifyContent: 'space-between' }}>
                                    <div className='col-auto'>
                                        {index !== 3 &&
                                            <span style={{ fontSize: '30px' }}>
                                                {currentLocation === 'NG' ? `₦` : '$'}
                                            </span>}
                                        <span style={{ letterSpacing: '-0.04em' }}>
                                            {currentLocation === 'NG' && info.price2 ? info.price2 : info.price}
                                        </span>

                                    </div>
                                    {index !== 3 && <div className='col-auto d-flex flex-end align-items-center'>
                                        <span style={{
                                            fontWeight: 400,
                                            fontSize: '13px'
                                        }}>
                                            {isAnnual ? 'annually' : '/month'}
                                        </span>
                                    </div>}
                                </div>
                            ) :
                                <span style={{ letterSpacing: '-0.04em' }}>
                                    {info.price}
                                </span>
                            }
                        </h2>

                        <div className='features'>
                            {info.features.map((feature) => (
                                <div className='feature'>
                                    <img src={Tick} alt="tick-icon" /> <span style={{ color: '#ffffff' }}> {feature}</span>
                                </div>
                            ))}
                        </div>


                        <div className='action'>
                            <Button
                                fullWidth
                                style={{ maxWidth: '100%' }}
                                variant={'bordered'}
                                icon={index != 3 && <img src={Goto} alt="tick-icon" style={{ marginLeft: 7 }} />}
                                type="submit"
                                label={info.link}
                                onClick={info.title === 'Free' ?
                                    () => gotoIndividualSignupPage('free') :
                                    info.title === 'Pro' ?
                                        () => gotoIndividualSignupPage('pro') :
                                        info.title === 'Enterprise' ?
                                            () => history.push("/contact-us") :
                                            () => gotoCorporateSignupPage(info.title)}
                            />
                        </div>
                    </div>
                </div>
            ))}
            {/* </Fade> */}
        </>
    )
}

export default PricingCard