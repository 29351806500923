import React, { useState } from 'react'
import "../assets/styles/pageStyles/faq.scss";
import ArrowDown from "../assets/images/arrow-down.svg";
import ArrowUp from "../assets/images/arrow-up.svg";

const SingleFAQ = ({ faq }) => {
    const [collapsed, setCollapsed] = useState(true)

    return (
        <div className='row d-flex align-items-center' style={{ borderTop: '1px solid #EBEBEB', padding: '15px 0', margin: '10px auto' }}>
            <div className='col-11 question'>
                {faq.q}
            </div>

            <div className='col-1'> <img src={collapsed ? ArrowDown : ArrowUp} onClick={() => setCollapsed(!collapsed)} alt="arrow-down" style={{ cursor: 'pointer' }} /> </div>
            {!collapsed && <div className='col-12 answer'>
                {faq.a}
            </div>}
        </div>
    )
}

export default SingleFAQ