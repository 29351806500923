import React, { useEffect, useState } from "react";
import "../../assets/styles/pageStyles/blog.scss";
import underline from "../../assets/images/Home/underline.svg"
import blogimg from "../../assets/images/blogimg.png"
import BlogCard from "./BlogCard";
import Axios from "axios";

const Blog = () => {
    const mediumApi = "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40StudioApp"
    const [blogData, setblogData] = useState([])

    useEffect(() => {
        async function fetchBlogPosts() {
            const res = await Axios.get(mediumApi)
            console.log('res', res);

            setblogData(
                res?.data.items.map(item => {
                    return {
                        title: item.title,
                        link: item.link,
                        thumbnail: item.thumbnail,
                        description: item.description
                    }
                })
            )
        }
        fetchBlogPosts()
    }, [])


    return (
        <>
            <div className="home-intro blog-container">
                <div className="container">
                    <div className="blog-wrapper">
                        Discover new ways to power <br /> your meetings with Studio
                    </div>
                    <div className='underline'>
                        <img src={underline} alt='underline' />
                    </div>
                </div>
            </div>

            {blogData.length && <div className="container bg-blue blog-section">
                <div className="row align-items-center">
                    {blogData?.map((blog, index) => (
                        <div className="col-12 col-md-3" key={index}>
                            <BlogCard data={blog} />
                        </div>
                    ))}
                </div>
            </div>}
        </>
    );
};

export default Blog;
