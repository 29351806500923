import React from "react";
import arrow from "../../assets/images/arrow-right.svg"
import "../../assets/styles/pageStyles/blog.scss";

const BlogCard = ({ data }) => {
    const ToText = (node) => {
        let tag = document.createElement("div");
        tag.innerHTML = node;
        node = tag.innerText;
        return node;
    }

    return (
        <div className="blog-box">
            <img className="blog-img" src={data.thumbnail} alt="blog" />
            <div className="blog-content">
                <h3>{data.title}</h3>
                <p>
                    {`${ToText(
                        data.description.substring(0, 500)
                    )}`}
                </p>
                <a
                    href={data.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    className='blog-nav'
                >
                    Read more <img src={arrow} alt='star-icon' />
                </a>
            </div>
        </div>
    );
};

export default BlogCard;
